import React, { useEffect, useState, useRef } from "react";
import ContentEditable from "react-contenteditable";

export default function Cell({ value: initialValue, row: { index }, column: { id, dataType, options }, dataDispatch, backgroundColor,fontSize,color}) {
  const [value, setValue] = useState({ value: initialValue, update: false });
  const contentEditableRef = useRef(null);

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);



  const applyFormatting = (command) => {
    if (contentEditableRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount === 0) return;
  
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().trim();
  
      if (!selectedText) return;
  
      let formattedText;
      switch (command) {
        case 'superscript':
          formattedText = `<sup>${selectedText}</sup>`;
          break;
        case 'subscript':
          formattedText = `<sub>${selectedText}</sub>`;
          break;
        case 'normal':
          const node = selection.anchorNode;
          if (node && (node.parentElement.tagName === 'SUB' || node.parentElement.tagName === 'SUP')) {
            const parent = node.parentElement;
            parent.insertAdjacentText('afterend', selectedText);
            parent.removeChild(node);
  
            const newRange = document.createRange();
            newRange.setStartAfter(parent.nextSibling);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
          } else {
            // Use a temporary div to strip HTML tags and keep plain text
            formattedText = selectedText.replace(/<\/?[^>]+(>|$)/g, "");
            range.deleteContents();
            const tempDiv = document.createElement("div");
            tempDiv.innerText = formattedText;
            const frag = document.createDocumentFragment();
            let node;
            while ((node = tempDiv.firstChild)) {
              frag.appendChild(node);
            }
            range.insertNode(frag);
  
            // Adjust the range to move the cursor after the inserted text
            const lastNode = frag.lastChild;
            if (lastNode) {
              const newRange = document.createRange();
              newRange.setStartAfter(lastNode);
              newRange.collapse(true);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
          break;
        case 'bullet':
          formattedText = `<ul  style="font-size:inherit;" class="table-bullet"><li style="font-size:inherit;">${selectedText}</li></ul>`;
          break;
        case 'number':
          formattedText = `<ol style="font-size:inherit;" class="table-ordered"><li style="font-size:inherit;" >${selectedText}</li></ol>`;
          break;
        default:
          return;
      }
  
      if (command !== 'normal') {
        range.deleteContents();
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = formattedText;
        const frag = document.createDocumentFragment();
        let node;
        let lastNode;
        while ((node = tempDiv.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);
  
        if (lastNode) {
          const newRange = document.createRange();
          newRange.setStartAfter(lastNode);
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
      }
    }
  };
  
  const alignParentDiv = (alignment) => {
    if (contentEditableRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount === 0) return;
  
      const range = selection.getRangeAt(0);
      let parent = range.commonAncestorContainer.parentElement;
      while (parent && !parent.classList.contains('table-td') && !parent.classList.contains('table-th')) {
        parent = parent.parentElement;
      }

      if (parent && (parent.classList.contains('table-td') || parent.classList.contains('table-th'))) {
        parent.style.textAlign = alignment;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.shiftKey) {
      switch (e.key) {
        case 'U':
          e.preventDefault();
          applyFormatting('superscript');
          break;
        case 'S':
          e.preventDefault();
          applyFormatting('subscript');
          break;
        case 'M':
          e.preventDefault();
          applyFormatting('normal');
          break;
        case 'B':
          e.preventDefault();
          applyFormatting('bullet');
          break;
        case 'O':
          e.preventDefault();
          applyFormatting('number');
          break;
        case 'ArrowLeft':
          e.preventDefault();
          alignParentDivLeft();
          break;
        case 'C':
          e.preventDefault();
          alignParentDivCenter();
          break;
        case 'ArrowRight':
          e.preventDefault();
          alignParentDivRight();
          break;
        default:
          break;
      }
    }
  };

  const alignParentDivLeft = () => {
    alignParentDiv('left');
  };
  
  const alignParentDivCenter = () => {
    alignParentDiv('center');
  };
  
  const alignParentDivRight = () => {
    alignParentDiv('right');
  };
  

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  let element;
  switch (dataType) {
    case "text":
      element = (
        <ContentEditable
          html={(value.value && value.value.toString()) || ""}
          onChange={(e) => {
            setValue({ value: e.target.value, update: false });
          }}

          onBlur={() => setValue((old) => ({ value: old.value, update: true }))}
          className='data-input'
          style={{ backgroundColor,fontSize,color}}
          ref={contentEditableRef}
        />
      );
      break;
    default:
      element = <span></span>;
      break;
  }

  return element;
}
