import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { DataGrid } from '@mui/x-data-grid';
import Select from 'react-select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import BlogsPreview from './BlogsPreview';
import Input from './Input';
import ImageLayout1 from '../../../components/ImageLayout/ImageLayout1';
import iFrame from '../../Blogs/components/iFrame';
import { fetchToken } from '../../../Auth';
import { arrayMoveImmutable } from 'array-move';
import { toast } from 'react-toastify';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'html-react-parser';
import {
    Editor,
    EditorState,
    RichUtils,
    CompositeDecorator,
    convertToRaw,
    convertFromRaw,
} from "draft-js";
import MyEditor from '../../../components/TextEditor/Editor';
import { stateToHTML, stateFromHTML } from 'draft-js-export-html';

import placeholderImg from '../../../assets/img/placeholder_img.svg';

// Validators
import isValidURL from '../../../services/validators/urlValidator';

import templatesData from '../../../data/templates';
import categories from '../../../data/blogCategories';

import { adminURL } from '../../../global';

// Table editor
import Table from '../../../components/TableEditor/TableEditor';
import '../../../components/TableEditor/TableEditor.css';

import { SketchPicker } from 'react-color';
import PaletteIcon from '@mui/icons-material/Palette';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import colorPickerHeader from '../../../assets/img/grey-fill-header.png';
import colorPickerStripes from '../../../assets/img/grey-fill-stripes.png';
import RightAlignIcon from '../../../assets/img/grey-align-right.png';
import LeftAlignIcon from '../../../assets/img/grey-align-left.png';
import CenterAlignIcon from '../../../assets/img/grey-align-center.png';
import tableRightAlign from '../../../assets/img/table-right.png';
import tableLeftAlign from '../../../assets/img/table-left.png';
import tableCenterAlign from '../../../assets/img/table-center.png';
import tableTitleRight from '../../../assets/img/titleRight.png';
import tableTitleLeft from '../../../assets/img/titleLeft.png';
import tableTitleCenter from '../../../assets/img/titleCenter.png';
import tableFooterRight from '../../../assets/img/footerRight.png';
import tableFooterLeft from '../../../assets/img/footerLeft.png';
import tableFooterCenter from '../../../assets/img/footerCenter.png';



const CreateBlogs = () => {

    const [pageId, setPageId] = useState('');
    const [templates, setTemplates] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [selectedAuthor, setSelectedAuthor] = useState({ 'label': '', 'value': '' });
    const [inputs, setInputs] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [additionalContent, setAdditionalContent] = useState([]);
    const contentRef = useRef([]);
    const [images, setImages] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]); // To store image data
    const [imgValid, setImgValid] = useState(false);
    const [altText, setAltText] = useState(false);
    const [linkNames, setLinkNames] = useState({});
    const [publishedDate, setPublishedDate] = useState('');
    const [blogTitle, setBlogTitle] = useState('');
    const [blogDesc, setBlogDesc] = useState('');
    const [schemaMarkup, setSchemaMarkup] = useState('');
    const [blogURL, setBlogURL] = useState('');
    const [keywords, setKeywords] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showCustomCategories, setShowCustomCategories] = useState(false);
    const [customCategories, setCustomCategories] = useState('');

    const [open, setOpen] = useState(false);
    const [imgModal1, setImgModal1] = useState(false);
    const [imgModal2, setImgModal2] = useState(false);
    const [imgModal3, setImgModal3] = useState(false);
    const [imgModalHorizontal, setImgModalHorizontal] = useState(false);

    const [masterKeywords, setMasterKeywords] = useState([]);
    const [frequency, setFrequency] = useState({});
    const [frequencyData, setFrequencyData] = useState([]);
    const [analyzeSEOModal, setAnalyzeSEOModal] = useState(false);

    const [contentID, setContentID] = useState(0);

    const [linkTutorial, setLinkTutorial] = useState(false);
    const [saveModal, setSaveModal] = useState(false);

    const navigate = useNavigate();

    const [colorsHeader, setColorsHeader] = useState({});
    const [colorsStripes, setColorsStripes] = useState({});
    const [resizableColumns, setResizableColumns] = useState({});


    const [textAligns, setTextAligns] = useState({});
    const [tableAligns, setTableAligns] = useState({});

    const [footerAlign, setFooterAlign] = useState({});
    const [tableTitleAlign, setTableTitleAlign] = useState({});

    const [fontSize, setFontSize] = useState({});

    const [colorPickerVisibleHeader, setColorPickerVisibleHeader] = useState({});
    const [colorPickerVisibleStripes, setColorPickerVisibleStripes] = useState({});


    useEffect(() => {
        const handleClickOutside = (event) => {
            additionalContent.forEach(item => {
                const colorPickerElement = document.getElementById(`color-picker-${item.id}`);
                if (colorPickerVisibleHeader[item.id] && colorPickerElement && !colorPickerElement.contains(event.target)) {
                    toggleColorPickerHeader(item.id);
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [additionalContent, colorPickerVisibleHeader]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            additionalContent.forEach(item => {
                const colorPickerElement = document.getElementById(`color-picker-stripes-${item.id}`);
                if (colorPickerVisibleStripes[item.id] && colorPickerElement && !colorPickerElement.contains(event.target)) {
                    toggleColorPickerStripes(item.id);
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [additionalContent, colorPickerVisibleStripes]);

    const isDarkColor = (color) => {
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance < 140;
    };

    const handleColorChangeHeader = (cid, color) => {
        const textColor = isDarkColor(color.hex) ? "#FFFFFF" : "#000000";
        setColorsHeader(prevState => ({
            ...prevState,
            [cid]: color
        }));
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        headerColors: color,
                        headerText:textColor,
                    };
                }
                return content;
            });
            return updatedState;
        });
    };


    const handleColorChangeStripes = (cid, color) => {
        const textColor = isDarkColor(color.hex) ? "#FFFFFF" : "#000000";
        setColorsStripes(prevState => ({
            ...prevState,
            [cid]: color
        }));
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        colorsStripes: color,
                        stripeText: textColor,
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const toggleResizableColumns = (tableId) => {
        setResizableColumns(prevState => ({
            ...prevState,
            [tableId]: !prevState[tableId],
        }));

        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === tableId) {
                    return {
                        ...content,
                        resizableColumns: true
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const updateTableHeaders = (headerElementStates, tableCard) => {
        const headers = tableCard.querySelectorAll('.table-th');
        headers.forEach((header, index) => {
            const state = headerElementStates[index];
            if (state) {
                header.innerHTML = state.innerHTML;
                header.style.cssText = state.style;
                Object.keys(state.attributes).forEach(attrName => {
                    header.setAttribute(attrName, state.attributes[attrName]);
                });

                // Update child elements recursively
                state.children.forEach((childState, i) => {
                    let childEl = header.childNodes[i];
                    if (!childEl) {
                        childEl = document.createElement(childState.tag);
                        header.appendChild(childEl);
                    }
                    updateNestedElement(childEl, childState);
                });
            }
        });
    };


    const updateTableBody = (bodyElementStates, tableCard) => {
        const bodies = tableCard.querySelectorAll('.table-td'); // Assuming this selects body cells

        bodies.forEach((body, index) => {
            const state = bodyElementStates[index];
            if (state) {
                body.innerHTML = state.innerHTML;
                body.style.cssText = state.style;
                Object.keys(state.attributes).forEach(attrName => {
                    body.setAttribute(attrName, state.attributes[attrName]);
                });


                state.children.forEach((childState, i) => {
                    let childEl = body.childNodes[i];
                    if (!childEl) {
                        childEl = document.createElement(childState.tag);
                        body.appendChild(childEl);
                    }
                    updateNestedElement(childEl, childState);
                });
            }
        });
    };


    const updateNestedElement = (element, state) => {
        element.innerHTML = state.innerHTML;
        element.style.cssText = state.style;
        Object.keys(state.attributes).forEach(attrName => {
            element.setAttribute(attrName, state.attributes[attrName]);
        });

        state.children.forEach((childState, i) => {
            let childEl = element.childNodes[i];
            if (!childEl) {
                childEl = document.createElement(childState.tag);
                element.appendChild(childEl);
            }
            updateNestedElement(childEl, childState);
        });
    };


    const handleTableTitle = (cid, title) => {
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        tableTitle: title
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const handleTableFooter = (cid, title) => {
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        tableFooter: title
                    };
                }
                return content;
            });
            return updatedState;
        });
    };


    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 60,
        height: 25,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(34px)',
                color: 'white',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'rgba(255, 197, 11, 1)',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 22,
                    height: 22,
                    backgroundColor: 'white',
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            backgroundColor: 'rgba(134, 146, 166, 1)',
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: 'rgba(238, 238, 238, 1)',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));


    const makeData = (rowCount = 2, columnCount = 2) => {
        let data = [];
        let options = [];

        for (let i = 0; i < rowCount; i++) {
            let row = {};
            for (let j = 0; j < columnCount; j++) {
                row[`column${j + 1}`] = '';
            }
            data.push(row);
        }

        // Generate columns with blank headers
        let columns = [];
        for (let j = 0; j < columnCount; j++) {
            columns.push({
                id: `column${j + 1}`,
                label: '',  // Blank header
                accessor: `column${j + 1}`,
                // maxWidth:'50px',
                dataType: "text",
                options: []
            });
        }


        return { columns: columns, data: data, skipReset: false };
    };




    const getAuthors = async () => {
        try {
            const response = await axios.get(`/get_authors`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setAuthors(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }



    const getTemplates = async () => {
        try {
            const response = await axios.get(`/get_blogs_templates`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                // console.log(response.data)
                setTemplates(response.data)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    const getPrivileges = async () => {
        try {
            const response = await axios.get(`/get_privileges`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                if (!response.data['blogs']['create']) {
                    navigate('/error')
                }
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
        }
    }

    const getPageId = async () => {
        try {
            const response = await axios.get(`/get_max_page_id/blogs`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setPageId(parseInt(response.data))
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate(`/niyantran-panel/${adminURL}/login`)
            }
        }
    }

    useEffect(() => {
        getPrivileges();
        getTemplates();
        getAuthors();
        getPageId();
    }, [])

    const setValue = (key, val) => {
        if (key === 'blogMainImg') {
            var fileInput = document.getElementById(key);
            if (fileInput.files && fileInput.files[0]) {
                var selectedFile = fileInput.files[0];
                const maxSize = 500000
                if (selectedFile.size > maxSize) {
                    alert('File size should be less than 500KB.')
                    document.getElementById(key).value = "";
                } else {
                    setInputValues(prevVal => {
                        let temp = prevVal;
                        temp[key] = selectedFile;
                        // temp[key] = selectedFile;
                        // { contentid, id, file, type: file.type }
                        return temp;
                    })
                }
            }
        }
        else if (key === 'published') {
            setPublishedDate(new Date(val).toISOString().split('T')[0])
            let date = new Date(val);
            let formattedDate = date.toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
            const day = date.getDate();
            const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                (day % 10 === 2 && day !== 12) ? 'nd' :
                    (day % 10 === 3 && day !== 13) ? 'rd' : 'th';
            const formattedDateWithSuffix = day + suffix + ' ' + formattedDate.substring(formattedDate.indexOf(' ') + 1);
            setInputValues(prevVal => {
                let temp = prevVal;
                temp[key] = formattedDateWithSuffix;
                return temp;
            })
        }
        else {
            setInputValues(prevVal => {
                let temp = prevVal;
                temp[key] = val
                return temp;
            })
        }


        if (key === 'blogTitle' && !blogURL) {
            setBlogTitle(val);
            setBlogURL(val.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, ''))
        }
    }

    const populateForEditing = () => {
        let temp = []
        let inp = (templates.find(temp => temp['templateId'] == selectedTemplate))['inputs']
        for (let key in inp) {
            if (inp[key]['type'] === 'text') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-12 d-flex justify-content-between align-items-end'>
                                <h6 className='bold'>{inp[key]['label']} <span className='text-danger'>*</span></h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='text' id={key} onChange={(evt) => setValue(key, evt.target.value)} />
                    </div>
                )
            }
            else if (inp[key]['type'] === 'img') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-12 img-layout-heading d-flex justify-content-between align-items-end'>
                                <h6 className='bold'>{inp[key]['label']} <span className='text-danger'>*</span></h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='file' accept="image/png, image/jpeg, image/jpg, image/gif" id={key} onChange={(evt) => setValue(key, '')} />
                    </div>
                )
            }
            else if (inp[key]['type'] === 'date') {
                temp.push(
                    <div className='admin-card p-3 my-3 d-flex flex-column'>
                        <div className='w-100 row'>
                            <div className='col-12 d-flex justify-content-between align-items-end'>
                                <h6 className='bold'>{inp[key]['label']} <span className='text-danger'>*</span></h6>
                                <Tooltip placement="right" size='small' title={inp[key]['desc']}>
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <input className='form-control medium-2' type='date' id={key} onChange={(evt) => setValue(key, evt.target.value)} />
                    </div>
                )
            }
        }
        setInputs(temp);
    }

    useEffect(() => {
        if (selectedTemplate) {
            populateForEditing()
        }
    }, [selectedTemplate]);

    const addEditor = () => {
        setAdditionalContent(prevContent => [...prevContent, { id: contentID, mode: 'editor', element: EditorState.createEmpty() }]);
        setContentID(contentID + 1)
    };



    const addImageAndText = async () => {
        setAdditionalContent(prevContent => {
            return [...prevContent,
            {
                'id': parseInt(contentID),
                'img_id': images,
                'mode': 'img-text',
                'element': EditorState.createEmpty()
            }
            ]
        })
        setImages(images + 1)
        setContentID(contentID + 1)
    }

    const addImageLayoutHorizontal = async () => {
        var fileInput1 = document.getElementById('img-choose-horizontal');
        var altText = document.getElementById('img-choose-horizontal-alt-text').value;
        let temp = [];
        let blobArray = [];
        const readFile = (file, id, contentid) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='w-100 corner blog-img-horizontal' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, altText })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 2);
        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>Horizontal Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-center align-items-center'>
                {temp}
            </div>
        </div>;
        setAdditionalContent(prevContent => [...prevContent, { id: parseInt(contentID), element: div, mode: 'img', layout: "horizontal" }]);
        setContentID(contentID + 1)
        setImgModalHorizontal(false);
        setImgValid(false)
        setAltText(false)
    }


    const addImageLayout1 = async () => {
        var fileInput1 = document.getElementById('img-choose-1-1');

        var altText1 = document.getElementById('img-choose-1-1-alt-text').value;

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid, imgNum) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img-1' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '1', altText: altText1 })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID, 1)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 2)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>1 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id: parseInt(contentID), element: div, mode: 'img', layout: '1' }]);
        setContentID(contentID + 1)
        setImgModal1(false);
        setImgValid(false)
        setAltText(false)
    };

    const addImageLayout2 = async () => {
        var fileInput1 = document.getElementById('img-choose-1');
        var fileInput2 = document.getElementById('img-choose-2');

        var altText1 = document.getElementById('img-choose-2-1-alt-text').value;
        var altText2 = document.getElementById('img-choose-2-2-alt-text').value;

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid, imgNum) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '2', altText: imgNum == 1 ? altText1 : altText2 })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID, 1), readFile(fileInput2.files[0], images + 2, contentID, 2)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 3)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>2 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying a single image">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id: parseInt(contentID), element: div, mode: 'img', layout: '2' }]);
        setContentID(contentID + 1)
        setImgModal2(false);
        setImgValid(false)
        setAltText(false)
    };


    const addImageLayout3 = async () => {
        var fileInput1 = document.getElementById('img-choose-3-1');
        var fileInput2 = document.getElementById('img-choose-3-2');
        var fileInput3 = document.getElementById('img-choose-3-3');

        var altText1 = document.getElementById('img-choose-3-1-alt-text').value;
        var altText2 = document.getElementById('img-choose-3-2-alt-text').value;
        var altText3 = document.getElementById('img-choose-3-3-alt-text').value;

        let temp = [];
        let blobArray = [];

        const readFile = (file, id, contentid, imgNum) => {
            return new Promise((resolve, reject) => {
                if (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        temp.push(<img id={`img-${id}`} className='blog-img-3' src={e.target.result} />);
                        blobArray.push({ contentid, id, file, type: file.type, layout: '3', altText: imgNum == 1 ? altText1 : imgNum == 2 ? altText2 : altText3 })
                        resolve();
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                } else {
                    resolve(); // Resolve immediately if no file
                }
            });
        };
        await Promise.all([readFile(fileInput1.files[0], images + 1, contentID, 1), readFile(fileInput2.files[0], images + 2, contentID, 2), readFile(fileInput3.files[0], images + 3, contentID, 3)]);
        setSelectedImages(prevImages => [...prevImages, ...blobArray]);
        setImages(images + 4)

        let div = <div className='my-3 admin-card p-3'>
            Content #{contentID}
            <div className='w-100 row'>
                <div className='col-6 d-flex align-items-end'>
                    <h6 className='bold'>3 Image Layout</h6>
                    <Tooltip placement="right" size='small' title="Layout used for displaying 3 images">
                        <IconButton>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className='col-6 d-flex justify-content-end'>
                    <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                        <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                        <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteContentBlock(contentID, 'img')} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div id='actual-img-content' className='w-100 d-flex justify-content-evenly align-items-center'>
                {temp}
            </div>
        </div>;

        setAdditionalContent(prevContent => [...prevContent, { id:parseInt(contentID), element: div, mode: 'img', layout: '3' }]);
        setContentID(contentID + 1)
        setImgModal3(false);
        setImgValid(false)
        setAltText(false)
    };

    const getAdditionalContent = (additionalContent, id) => {
        return additionalContent.find(item => item.id === id) || {};
    };



    const tableElement = (element, mode) => {
        const contentEditableStates = saveNestedDivsState(`#table-card-${element['id']} .table-th`);
        const dataInputStates = saveNestedDivsState(`#table-card-${element['id']} .table-td`);
        const specificAdditionalContent = getAdditionalContent(additionalContent, element['id']);
        const { element: _, ...rest } = specificAdditionalContent;

        return {
            contentEditableStates,
            specificAdditionalContent: rest,
            dataInputStates
        };
    };
    const getTableStates = () => {
        const tableCardElements = additionalContent.filter(element => {
            const elementSelector = `#table-card-${element['id']}`;
            return document.querySelector(elementSelector) !== null;
        });

        return tableCardElements.reduce((acc, element) => {
            const tableState = tableElement(element, 'save');
            acc[element['id']] = { tableState };
            return acc;
        }, {});
    };



    const validateTableData = () => {
        const tableCardElements = additionalContent.filter(element => {
            const elementSelector = `#table-card-${element['id']}`;
            return document.querySelector(elementSelector) !== null;
        });

        for (let element of tableCardElements) {
            const tableCardSelector = `#table-card-${element['id']}`;
            const dataInputs = document.querySelectorAll(`${tableCardSelector} .data-input`);
            // const headerInputs = document.querySelectorAll(`${tableCardSelector} .th-content-table`);

            for (let input of dataInputs) {
                if (!input.innerText.trim()) {
                    alert(`Empty input found in table with ID: ${element['id']}`);
                    return { isValid: false, tableId: element['id'] };
                }
            }

            // for (let input of headerInputs) {
            //     if (!input.innerText.trim()) {
            //         alert(`Empty header found in table with ID: ${element['id']}`);
            //         return { isValid: false, tableId: element['id'] };
            //     }
            // }
        }

        return { isValid: true, tableId: null };
    };
    const saveBlog = async () => {
        if (!selectedAuthor['value']) {
            alert('Select an Author for this blog!');
            return;
        }
        if (!blogURL) {
            alert('Please provide a URL for your blog!');
            return;
        }
        if (!blogDesc) {
            alert('Please set a description for the blog!');
            return;
        }
        // if (!schemaMarkup) {
        //     alert('Please set schema markup for the blog!');
        //     return;
        // }
        let markup = ''
        if (schemaMarkup) {
            try {
                markup = JSON.parse(schemaMarkup);
            }
            catch (err) {
                alert('Invalid JSON for schema markup!')
                return;
            }
        }
        if (!keywords) {
            alert('Please set keywords for this blog!');
            return;
        }
        if (!selectedCategories.length) {
            alert('Please select at least one category for this blog!');
            return;
        }
        if (!publishedDate) {
            toast.error('Please enter the publishing date!');
            return;
        }
        if (showCustomCategories && !customCategories.length) {
            toast.error('You have not entered any custom categories!');
            return;
        }
        if (showCustomCategories && customCategories.length) {
            let temp = customCategories.split(',').map(item => item.toLowerCase().trim())
            for (let cat of categories) {
                if (temp.includes(cat.toLowerCase().trim())) {
                    toast.error(`${cat} already exists in the dropdown!`);
                    return;
                }
            }

        }

        let tableState = getTableStates()

        const validationResult = validateTableData();



        if (!validationResult.isValid) {
            return;
        }

        additionalContent.map(element => {
            if ('fontSize' in element) {
                if (typeof element.fontSize !== 'object') {
                    const matchingOption = fontSizeOptions.find(option => option.label === String(element.fontSize));
                    if (matchingOption) {
                        element.fontSize = matchingOption;
                    } else {
                        alert(`Invalid font size in table with ID: ${element['id']}`);
                        return true;
                    }
                } else if (element.fontSize.label === '0') {
                    alert(`Empty font size in table with ID: ${element['id']}`);
                    return true;
                }
            }
            return false;
        });


        setSaveModal(true)

        let images = {}

        //Save Blog Main Image
        let mainImg = inputValues['blogMainImg']
        if (!mainImg) {
            toast.error(`Choose a feature image for this blog!`);
            return;
        }
        images['img-main-img'] = {
            type: mainImg['type']
        }
        try {
            const formData = new FormData();
            formData.append('pageId', pageId);
            formData.append('id', `main-img.${mainImg['type'] === 'image/jpeg' ? 'jpeg' : mainImg['type'] === 'image/jpg' ? 'jpg' : mainImg['type'] === 'image/png' ? 'png' : 'jpg'}`);
            formData.append('file', mainImg);
            const response = await axios.post('/upload_blog_images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log('File uploaded successfully:', response.data);
        } catch (error) {
            // alert('Error while uploading Main image...')
            console.error('Error uploading file:', error);
        }

        for (let img of selectedImages) {
            try {
                const formData = new FormData();
                formData.append('pageId', pageId);
                formData.append('id', `${img['id']}.${img['type'] === 'image/jpeg' ? 'jpeg' : img['type'] === 'image/jpg' ? 'jpg' : img['type'] === 'image/png' ? 'png' : 'jpg'}`);
                formData.append('file', img['file']);
                const response = await axios.post('/upload_blog_images', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                // console.log('File uploaded successfully:', response.data);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

        for (let img of selectedImages) {
            images[`img-${img['id']}`] = {
                type: img['type'],
                altText: img['altText']
            }

        }




        const newBlog = {
            'pageId': pageId,
            'templateId': parseInt(selectedTemplate),
            'authorId': selectedAuthor['value'],
            'url': blogURL,
            'publishedDate': publishedDate,
            'inputs': inputValues,
            'additionalContent': (additionalContent.map(element => processElement(element, 'save'))).join(' '),
            'desc': blogDesc,
            'schemaMarkup': markup,
            'keywords': keywords.split(',').map(item => item.trim()).filter(item => item !== ''),
            'categories': selectedCategories.map(cat => cat['value']),
            'customCategories': customCategories.split(',').map(item => item.trim()).map(str => str.charAt(0).toUpperCase() + str.slice(1)).filter(str => str !== ''),
            'title': document.getElementById('blogTitle').value,
            'seoTitle': blogTitle,
            'images': images,
            'active': false,
            'tableEdited': tableState,
        };
        // console.log(newBlog)
        try {
            const response = await axios.post(`/create_new_blog`, newBlog, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setSaveModal(false)
                navigate(`/niyantran-panel/${adminURL}/blogs-admin`)
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 400) {
                setSaveModal(false)
                toast.error('This Blog URL already exists! Please change the Blog title or URL');
                return
            }
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    };

    const readImg = (file, contentid, id) => {
        if (file) {
            let blobArray = []
            const reader = new FileReader();
            reader.onload = function (e) {
                const imagePreview = document.getElementById(`img-text-img-${id}`)
                imagePreview.src = e.target.result;
                imagePreview.classList.add('blog-img-text');
                blobArray.push({ contentid, id, file, type: file.type })
                setSelectedImages(prevImages => [...prevImages, ...blobArray]);
                setImages(images + 1)
                setContentID(contentID + 1)
            };
            reader.readAsDataURL(file);
        }
    }


    const saveNestedDivsState = (selector) => {
        const states = {};
        document.querySelectorAll(selector).forEach((element, index) => {
            const getNestedStates = (el) => {
                const childrenStates = [];
                el.childNodes.forEach((child) => {
                    if (child.nodeType === Node.ELEMENT_NODE) {
                        childrenStates.push(getNestedStates(child));
                    }
                });
                return {
                    tag: el.tagName.toLowerCase(),
                    innerHTML: el.innerHTML,
                    style: el.style.cssText,
                    attributes: Array.from(el.attributes).reduce((attrs, attr) => {
                        attrs[attr.name] = attr.value;
                        return attrs;
                    }, {}),
                    children: childrenStates
                };
            };

            states[index] = getNestedStates(element);
        });
        return states;
    };




    const processElement = (element, mode) => {
        if (element['element']) {
            if (element['mode'] === 'editor') {
                let html = stateToHTML(element['element'].getCurrentContent())
                if (mode !== 'save') {
                    let arr = html.split('##')
                    for (let i = 0; i < arr.length; i += 1) {
                        if (arr[i].includes('__')) {
                            arr[i] = `<a target='_blank' href='//${(arr[i].split('__'))[1]}'>${(arr[i].split('__'))[0]}</a>`
                        }
                    }
                    html = arr.join('')
                }
                return `<div data-content-id="${element['id']}" data-content-type='text' class='my-2'>` + html + "</div>"
            }
            else if (element['mode'] === 'img-text') {
                let html = stateToHTML(element['element'].getCurrentContent()).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                let src = ''
                if (mode !== 'save') {
                    // src = document.getElementById(`img-text-img-${element['id']}`).getAttribute('src')
                    src = document.querySelector(`[data-content-id="img-text-img-contentid-${element['id']}"]`).getAttribute('src')
                }
                let doc = selectedImages.find(item => item['contentid'] == element['id'])
                try {
                    return `<div data-content-id="${element['id']}" data-content-type="img-text" class='w-100 row'><div class='col-6 p-1 d-flex flex-column justify-content-center align-items-center'><img id='${`img-${doc["id"]}`}' src='${src}' class='blog-img-text'/></div><div class='col-6 p-1'>${html}</div></div>`
                } catch (err) {
                    console.log(err)
                    return ''
                }
            }
            else if (element['mode'] === 'img') {
                if (element['layout'] === 'horizontal' || element['layout'] == '1' || element['layout'] == '2' || element['layout'] == '3') {
                    let item = ReactDOMServer.renderToString(element['element']).replace(/admin-card/g, '')
                    item = new DOMParser().parseFromString(item, "text/html")
                    item = item.querySelector('#actual-img-content')
                    if (mode == 'save') {
                        Array.from(item.querySelectorAll('img')).forEach(img => img.setAttribute('src', ''))
                    }
                    return `<div data-content-id="${element['id']}" data-content-type='img' data-layout=${element['layout']} class='my-1'>` + item.outerHTML + "</div>";
                }
            }
            else if (element['mode'] === 'link') {
                if (document.getElementById(`link-input-name-${element['id']}`)) {
                    return ReactDOMServer.renderToString(
                        <a
                            target="_blank"
                            href={`//${document.getElementById(`link-input-url-${element['id']}`).value}`}
                            data-content-id={String(element['id'])}
                            data-content-type="link"
                            className='my-2 btn-yellow text-black text-center p-1 d-flex justify-content-center align-items-center'
                        >
                            {document.getElementById(`link-input-name-${element['id']}`).value}
                        </a>
                    )
                }
            }
            else if (element['mode'] === 'iframe') {
                if (document.getElementById(`iframe-name-${element['id']}`) && document.getElementById(`iframe-src-${element['id']}`)) {
                    let name = document.getElementById(`iframe-name-${element['id']}`).value
                    let src = document.getElementById(`iframe-src-${element['id']}`).value

                    return ReactDOMServer.renderToString(
                        <div data-content-id={String(element['id'])} data-content-type="iframe" className='my-2 w-100 d-flex justify-content-center align-items-center'>
                            <iFrame src={src} name={name} />
                        </div>
                    )
                }
            }
            else if (element['mode'] === 'form-button') {
                let btnName = ''
                btnName = document.getElementById(`form-button-name-${element['id']}`).value
                let form = 'contact-us'
                form = document.getElementById(`form-button-form-${element['id']}`).value
                return ReactDOMServer.renderToString(
                    <div data-content-id={String(element['id'])} data-content-type="form-button" className='my-2 d-flex align-items-center'>
                        <button data-attached-form={form} className='clickable-form-button p-2 btn-yellow text-center text-black'>{btnName}</button>
                    </div>
                )
            }
            else if (element['mode'] === 'table-data') {
                let htmlElement = document.querySelector(`#table-card-${element['id']} .table-${element['id']}`);

                if (htmlElement) {

                    let clonedElement = htmlElement.cloneNode(true);

                    let editableElements = clonedElement.querySelectorAll('[contenteditable="true"]');

                    let resizers = clonedElement.querySelectorAll('.resizer');
                    resizers.forEach(resizer => {
                        resizer.classList.remove('resizer');
                    });


                    editableElements.forEach(el => {
                        el.setAttribute('contenteditable', 'false');
                    });
                    let contentType;
                    let htmlString = clonedElement.outerHTML;
                    if (element['tableAlignement'] === 'center') {
                        contentType = 'center'
                    }
                    else if (element['tableAlignement'] === 'left') {
                        contentType = 'start'
                    } else {
                        contentType = 'end'
                    }
                    return `<div data-content-column="${element['column']}" data-content-row="${element['row']}" data-content-id="${element['id']}" data-content-type='table' class='my-2' style='display:flex;justify-content:${contentType}'>` + htmlString + "</div>";
                } else {
                    console.error("HTML element not found");
                    return '';
                }
            }


        } else {
            return ""
        }
    }

    const checkBlogContent = () => {
        //Check links
        // for (let key in links) {
        //     if (!isValidURL(links[key]['url'])) {
        //         alert('One of your URL links is invalid, please check again!')
        //         return false;
        //     }
        // }

        return true
    }

    const openPreview = () => {
        if (!blogTitle) {
            alert('Please set Blog Title');
            return;
        }
        if (!selectedAuthor['value']) {
            alert('Please select Blog Author');
            return;
        }
        if (checkBlogContent()) {
            setOpen(true)
        }
    }

    const deleteContentBlock = (id, mode) => {
        let tableState = getTableStates()
        if (mode === 'link') {
            document.getElementById(`link-card-${id}`).outerHTML = "";
            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
        else if (mode === 'editor') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
        else if (mode === 'iframe') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
            let tableState = getTableStates()
            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
        else if (mode === 'img') {
            setSelectedImages(prevContent => {
                let temp = prevContent.filter(item => item['contentid'] !== id)
                return temp;
            })
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
            let tableState = getTableStates()
            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
        else if (mode === 'form-button') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
            let tableState = getTableStates()
            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
        else if (mode === 'table-data') {
            setAdditionalContent(prevContent => {
                let temp = prevContent.filter(item => item['id'] !== id)
                return temp;
            });
            
            let tableState = getTableStates()
            for (const key in tableState) {
                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    if (key != id){
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                    }
                }
            }, 1000);
        }
    }

    const addiFrame = () => {
        setAdditionalContent(prevState => {
            return [...prevState,
            {
                'id': contentID,
                'mode': 'iframe',
                'element': <div id={`iframe-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Video Name</h6>
                            <Tooltip placement="right" size='small' title="Video Name">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'iframe')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                    <input placeholder='Ex. Sciative Road Ahead Talks' className='form-control' type='text' id={`iframe-name-${contentID}`} />
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Video URL</h6>
                            <Tooltip placement="right" size='small' title="Video URL">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input placeholder='Paste URL here' className='form-control' type='text' id={`iframe-src-${contentID}`} />
                </div>
            }
            ]
        })
        setContentID(contentID + 1)
    }

    const handleLinkNameChange = (id, val) => {
        setLinkNames(prevState => {
            let temp = prevState;
            temp[id] = val;
            return temp;
        })
    }

    const tableStyle = [
        { '_id': 0, 'table_name': 'Default table' },
        { '_id': 1, 'table_name': 'Striped' },
    ]

    const fontSizeOptions = [
        { '_id': 0, 'value': '0.75rem', 'label': '12' },
        { '_id': 1, 'value': '0.875rem', 'label': '14' },
        { '_id': 2, 'value': '1rem', 'label': '16' },
        { '_id': 3, 'value': '1.125rem', 'label': '18' },
        { '_id': 4, 'value': '1.25rem', 'label': '20' },
        { '_id': 5, 'value': '1.5rem', 'label': '24' },
        { '_id': 6, 'value': '1.75rem', 'label': '28' },
        { '_id': 7, 'value': '2rem', 'label': '32' },
        { '_id': 8, 'value': '2.5rem', 'label': '40' },
        { '_id': 9, 'value': '3rem', 'label': '48' },
        { '_id': 10, 'value': '4rem', 'label': '64' },
    ];


    const handleFontSizeChange = (cid, selectedFont) => {
        // console.log(selectedFont)
        if (selectedFont) {
            setFontSize(prevState => ({
                ...prevState,
                [cid]: selectedFont['value']
            }));
        }
        else {
            setFontSize(prevState => ({
                ...prevState,
                [cid]: '0.75rem'
            }));
        }


        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    if (selectedFont) {
                        return {
                            ...content,
                            fontSize: selectedFont
                        };
                    }
                    else {
                        return {
                            ...content,
                            fontSize: { '_id': 0, 'value': '0.75rem', 'label': '12' },
                        };
                    }

                }
                return content;
            });
            return updatedState;
        });

    };






    const handleTableAlignChange = (tableId, alignment) => {
        setTableAligns((prev) => ({
            ...prev,
            [tableId]: alignment,
        }));
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === tableId) {
                    return {
                        ...content,
                        tableAlignement: alignment
                    };
                }
                return content;
            });
            return updatedState;
        });
    };



    const handleTextAlignChange = (cid, alignment) => {
        setTextAligns(prevState => ({
            ...prevState,
            [cid]: alignment
        }));
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        textAlign: alignment
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const handleTableFooterAlign = (cid, alignment) => {
        setFooterAlign(prevState => ({
            ...prevState,
            [cid]: alignment
        }));

        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        footerAlign: alignment
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const handleTableTitleAlign = (cid, alignment) => {
        setTableTitleAlign(prevState => ({
            ...prevState,
            [cid]: alignment
        }));

        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        tabelTitleAlign: alignment
                    };
                }
                return content;
            });
            return updatedState;
        });
    };

    const renderTableTitle = (tableId) => {
        const currentAlignment = tableTitleAlign[tableId] || 'center';

        switch (currentAlignment) {
            case 'left':
                return (
                    <Tooltip title="Table Title Left Align" arrow>
                        <div onClick={() => handleTableTitleAlign(tableId, 'center')}>
                            <img src={tableTitleLeft} alt="Table Title Left Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'center':
                return (
                    <Tooltip title="Table Title Center Align" arrow>
                        <div onClick={() => handleTableTitleAlign(tableId, 'right')}>
                            <img src={tableTitleCenter} alt="Table Title Center Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'right':
                return (
                    <Tooltip title="Table Title Right Align" arrow>
                        <div onClick={() => handleTableTitleAlign(tableId, 'left')}>
                            <img src={tableTitleRight} alt="Table Title Right Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );

            default:
                return null;
        }
    };

    const renderTableFooter = (tableId) => {
        const currentAlignment = footerAlign[tableId] || 'center';

        switch (currentAlignment) {
            case 'left':
                return (
                    <Tooltip title="Footer Left Align" arrow>
                        <div onClick={() => handleTableFooterAlign(tableId, 'center')}>
                            <img src={tableFooterLeft} alt="Footer Left Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'center':
                return (
                    <Tooltip title="Footer Center Align" arrow>
                        <div onClick={() => handleTableFooterAlign(tableId, 'right')}>
                            <img src={tableFooterCenter} alt="Footer Center Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'right':
                return (
                    <Tooltip title="Footer Right Align" arrow>
                        <div onClick={() => handleTableFooterAlign(tableId, 'left')}>
                            <img src={tableFooterRight} alt="Footer Right Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );

            default:
                return null;
        }
    };

    const renderTableAlignmentIcon = (tableId) => {
        const currentAlignment = tableAligns[tableId] || 'center';

        switch (currentAlignment) {
            case 'left':
                return (
                    <Tooltip title="Table Align: Left" arrow>
                        <div onClick={() => handleTableAlignChange(tableId, 'center')}>
                            <img src={tableLeftAlign} alt="Left Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'center':
                return (
                    <Tooltip title="Table Align: Center" arrow>
                        <div onClick={() => handleTableAlignChange(tableId, 'right')}>
                            <img src={tableCenterAlign} alt="Center Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'right':
                return (
                    <Tooltip title="Table Align: Right" arrow>
                        <div onClick={() => handleTableAlignChange(tableId, 'left')}>
                            <img src={tableRightAlign} alt="Right Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );

            default:
                return null;
        }
    };


    const renderAlignmentIcon = (tableId) => {
        const currentAlignment = textAligns[tableId] || 'left';

        switch (currentAlignment) {
            case 'left':
                return (
                    <Tooltip title="Text Left Align" arrow>
                        <div onClick={() => handleTextAlignChange(tableId, 'center')}>
                            <img src={LeftAlignIcon} alt="Left Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'center':
                return (
                    <Tooltip title="Text Center Align" arrow>
                        <div onClick={() => handleTextAlignChange(tableId, 'right')}>
                            <img src={CenterAlignIcon} alt="Center Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );
            case 'right':
                return (
                    <Tooltip title="Text Right Align" arrow>
                        <div onClick={() => handleTextAlignChange(tableId, 'left')}>
                            <img src={RightAlignIcon} alt="Right Align" className='table-alignement' />
                        </div>
                    </Tooltip>
                );

            default:
                return null;
        }
    };


    const handleTableStyleChange = (cid, selectedStyle) => {
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        tableStyle: selectedStyle
                    };
                }
                return content;
            });
            return updatedState;
        });

    };




    const toggleColorPickerHeader = (cid) => {
        setColorPickerVisibleHeader(prevState => ({
            ...prevState,
            [cid]: !prevState[cid]
        }));


    };
    const toggleColorPickerStripes = (cid) => {
        setColorPickerVisibleStripes(prevState => ({
            ...prevState,
            [cid]: !prevState[cid]
        }));
    };




    const MIN_COUNT = 2;
    const MAX_COLUMNS = 10;
    const MAX_ROWS = 30;

    const createTable = (cid) => {
        const rowCountElement = document.getElementById(`rows-${cid}`);
        const columnCountElement = document.getElementById(`columns-${cid}`);

        if (!rowCountElement || !columnCountElement) {
            alert('Row count and column count fields are required.');
            return;
        }

        const rowCount = parseInt(rowCountElement.value, 10);
        const columnCount = parseInt(columnCountElement.value, 10);

        if (isNaN(rowCount) || rowCount < MIN_COUNT || rowCount > MAX_ROWS) {
            alert(`Row count must be a positive integer between ${MIN_COUNT} and ${MAX_ROWS}.`);
            return;
        }

        if (isNaN(columnCount) || columnCount < MIN_COUNT || columnCount > MAX_COLUMNS) {
            alert(`Column count must be a positive integer between ${MIN_COUNT} and ${MAX_COLUMNS}.`);
            return;
        }

        const { columns, data, skipReset } = makeData(rowCount, columnCount);
        setAdditionalContent(prevState => {
            const updatedState = prevState.map(content => {
                if (content.id === cid) {
                    return {
                        ...content,
                        mode: "table-data",
                        data: data,
                        skipReset: skipReset,
                        columns: columns,
                        tableStyle: 0,
                        tableAlignement: 'center',
                        textAlign: 'center',
                        headerColors: 'white',
                        colorsStripes: 'rgb(242, 242, 242)',
                        resizableColumns: true,
                        row: rowCount,
                        column: columnCount,
                        fontSize: { '_id': 0, 'value': '0.75rem', 'label': '12' },
                        tableTitle: '',
                        tableFooter: '',
                        headerText: 'black',
                        stripeText: 'black',
                        tabelTitleAlign: 'center',
                        footerAlign: 'center',
                        element: (
                            <div id={`table-card-${content.id}`} className='my-3 table-card admin-card p-3'>

                            </div>

                        )
                    };
                }
                return content;
            });

            return updatedState;
        });
    };


    const addTableButton = () => {
        setAdditionalContent(prevState => {
            let cid = contentID;
            return [...prevState, {
                id: contentID,
                mode: "table",
                table_type: 0,
                element: (
                    <div id={`table-card-${contentID}`} className='my-3 table-card admin-card p-3'>
                        Table: #{contentID}
                        <div className='w-100 row'>
                            <div className='col-6 d-flex align-items-end'>
                                <h6>Insert column & rows count to generate table</h6>
                            </div>
                        </div>
                        <div className='w-100 row'>
                            <div className='col-4'>
                                <h6 className="font-weight-bold">Column Count <span className='text-danger'>*</span></h6>
                                <TextField
                                    id={`columns-${cid}`}
                                    type="number"
                                    // onChange={(e) =>{ updateTableDimension(cid, 'columnCount', Number(e.target.value))}}
                                    inputProps={{ min: 1, max: 10 }}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </div>
                            <div className='col-4'>
                                <h6 className="font-weight-bold">Row Count  <span className='text-danger'>*</span></h6>
                                <TextField
                                    id={`rows-${cid}`}
                                    type="number"
                                    // value={tableDimensions[cid]?.rowCount || 1}
                                    // onChange={(e) => updateTableDimension(cid, 'rowCount', Number(e.target.value))}
                                    inputProps={{ min: 1, max: 20 }}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </div>

                            <div className='col-4 d-flex justify-content-center align-items-center'>
                                <Button className='w-100 mt-4' variant='contained' onClick={() => createTable(cid)}>Create Table</Button>
                            </div>
                        </div>
                    </div>
                )
            }];
        });
        setContentID(contentID + 1);
    };



    const addFormButton = () => {
        setAdditionalContent(prevState => {
            return [...prevState, {
                'id': contentID,
                'mode': 'form-button',
                'element': <div id={`form-button-section-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Button Name</h6>
                            <Tooltip placement="right" size='small' title="Text on the button">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(contentID)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(contentID)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'form-button')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <input placeholder='Ex. Request a Demo' className='form-control' type='text' id={`form-button-name-${contentID}`} />
                        <div className='w-100 row'>
                            <div className='col-6 d-flex align-items-end'>
                                <h6 className='bold'>Choose Form</h6>
                                <Tooltip placement="right" size='small' title="Form to be displayed in the popup modal">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <select className='form-select' id={`form-button-form-${contentID}`}>
                            <option selected value="contact-us">Contact Us</option>
                            <option value="request-demo">Request a Demo</option>
                        </select>
                    </div>
                </div>
            }]
        })
        setContentID(contentID + 1)
    }
    const addLink = () => {

        setAdditionalContent(prevState => {
            let cid = contentID
            return [...prevState, {
                'id': contentID,
                'mode': "link",
                'element': <div id={`link-card-${contentID}`} className='my-3 link-card admin-card p-3'>
                    ID: #{contentID}
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>Link Name</h6>
                            <Tooltip placement="right" size='small' title="Clickable text to display on the blog.">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <IconButton onClick={() => shiftUp(cid)} aria-label="shift-up">
                                <ArrowUpwardIcon />
                            </IconButton>
                            <IconButton onClick={() => shiftDown(cid)} aria-label="shift-down">
                                <ArrowDownwardIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteContentBlock(contentID, 'link')} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                    <input onChange={(evt) => handleLinkNameChange(contentID, evt.target.value)} placeholder='Ex. Go to Google' className='form-control' type='text' id={`link-input-name-${contentID}`} />
                    <div className='w-100 row'>
                        <div className='col-6 d-flex align-items-end'>
                            <h6 className='bold'>URL</h6>
                            <Tooltip placement="right" size='small' title="Link to the website where you want to redirect users">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <input placeholder='Ex. www.google.com' className='form-control' type='text' id={`link-input-url-${contentID}`} />
                </div>
            }]
        })
        setContentID(contentID + 1)
    }

    const handleChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 500000; // in bytes

        if (file && file.size > maxSize) {
            setImgValid(false);
        } else {
            setImgValid(true);
        }
    };

    const handleAltChange = (event) => {
        if (event.target.value) {
            setAltText(true)
        } else {
            setAltText(false)
        }
    }


    const analyzeSEO = async () => {
        if (!selectedCategories.length) {
            toast.error('You have not selected any categories!');
            return;
        }
        try {
            const response = await axios.post(`/get_keywords`, { categories: selectedCategories.map(item => item.value) }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`,
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setMasterKeywords(response.data)
                let freq = {}
                for (let keyword of response.data) {
                    freq[keyword] = 0
                }
                let contents = []
                for (let content of additionalContent) {
                    if (content['mode'] === 'editor') {
                        contents = [...contents, ...content['element'].getCurrentContent().getPlainText().toLowerCase().split(' ')]
                    }
                }
                for (let word of contents) {
                    let txts = word.split('\n');
                    for (let txt of txts) {
                        if (txt in freq) {
                            freq[txt] += 1
                        }
                    }
                }
                setFrequency(freq);
                let tableData = [];
                for (let keyword in freq) {
                    tableData.push({ id: keyword, count: freq[keyword] })
                }
                setFrequencyData(tableData.sort((a, b) => {
                    return b.count - a.count;
                }));
                setAnalyzeSEOModal(true);
            }
        } catch (err) {
            console.error("Error", err);
            if (err.response.status === 401) {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        contentRef.current = additionalContent
    }, [additionalContent])

    const shiftUp = (id) => {
        let originalPos
        let tableState = getTableStates()
        for (let i = 0; i < contentRef.current.length; i += 1) {
            if (contentRef.current[i]['id'] == id) {
                originalPos = i
                break
            }
        }
        if (originalPos !== null && originalPos !== undefined && originalPos != 0) {

            let newArr = arrayMoveImmutable(contentRef.current, originalPos, originalPos - 1)


            setAdditionalContent(newArr);

            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                }
            }, 1000);
        }
    }

    const shiftDown = (id) => {
        let originalPos
        let tableState = getTableStates()
        for (let i = 0; i < contentRef.current.length; i += 1) {
            // console.log(id, i, contentRef.current[i]['id'])
            if (contentRef.current[i]['id'] == id) {
                originalPos = i
                break
            }
        }
        if (originalPos !== null && originalPos !== undefined && originalPos < contentRef.current.length) {
            let newArr = arrayMoveImmutable(contentRef.current, originalPos, originalPos + 1)
            setAdditionalContent(newArr);


            for (const key in tableState) {

                setAdditionalContent(prevState => {
                    const updatedState = prevState.map(content => {
                        if (content.id === key) {
                            return {
                                ...content,
                                resizableColumns: true
                            };
                        }
                        return content;
                    });
                    return updatedState;
                });

                setResizableColumns(prevState => ({
                    ...prevState,
                    [key]: true,
                }));
            }

            setTimeout(() => {
                for (const key in tableState) {
                    const tableCard = document.querySelector(`.table-${key}`);
                    const headerElement = tableState[key]['tableState']['contentEditableStates'];
                    const bodyElement = tableState[key]['tableState']['dataInputStates'];
                    updateTableHeaders(headerElement, tableCard);
                    updateTableBody(bodyElement, tableCard);
                }
            }, 1000);
        }
    }



    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'>
                        <IconButton onClick={() => navigate(`/niyantran-panel/${adminURL}/blogs-admin`)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div className='col-8'>
                        <h1 className='bold '>Create new Blog</h1>
                        <p className='medium-3 text-muted'>Manage Blog content by choosing a template.</p>
                    </div>
                    <div className='col-3 py-2 d-flex flex-column'>
                        <h6 className='bold medium-2 text-muted'>Choose Template</h6>
                        <select onChange={(evt) => setSelectedTemplate(parseInt(evt.target.value))} class="form-select" aria-label="Select template">
                            <option hidden selected>Select a template</option>
                            {
                                templates.map(item => <option value={item['templateId']}>{item['templateName']}</option>)
                            }
                        </select>
                    </div>
                </div>

                {
                    selectedTemplate ?
                        <>
                            <div className='mt-4 col-8'>
                                {inputs}

                                {
                                    additionalContent.map((item, idx) => (
                                        item['mode'] === 'editor' ?
                                            <div id={`editor-card-${item['id']}`} className='admin-card p-3 my-3' key={idx}>
                                                Content #{item['id']}
                                                <div className='w-100 row'>
                                                    <div className='col-6 d-flex align-items-end'>
                                                        <h6 className='bold'>Add Content</h6>
                                                        <Tooltip placement="right" size='small' title="Insert your blog content">
                                                            <IconButton>
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='col-6 d-flex justify-content-end'>
                                                        <IconButton onClick={() => setLinkTutorial(true)} aria-label="shift-up">
                                                            <LinkIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => shiftUp(item['id'])} aria-label="shift-up">
                                                            <ArrowUpwardIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => shiftDown(item['id'])} aria-label="shift-down">
                                                            <ArrowDownwardIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => deleteContentBlock(item['id'], 'editor')} aria-label="delete">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                                <MyEditor editorState={item['element']} setEditorState={updatedEditorState => {
                                                    const updatedContent = [...additionalContent];
                                                    // updatedContent[idx] = updatedEditorState;
                                                    (updatedContent.find(con => con['id'] === item['id']))['element'] = updatedEditorState
                                                    setAdditionalContent(updatedContent);
                                                }} />
                                            </div>
                                            :
                                            item['mode'] === 'img-text' ?
                                                <div id={`img-text-section-${item['id']}`} className='admin-card p-3 my-3' key={idx}>
                                                    Content #{item['id']}
                                                    <div className='w-100 row'>
                                                        <div className='col-6 d-flex align-items-end'>
                                                            <h6 className='bold'>Add Image & Text</h6>
                                                            <Tooltip placement="right" size='small' title="Insert your blog content">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                        <div className='col-6 d-flex justify-content-end'>
                                                            <IconButton onClick={() => shiftUp(item['id'])} aria-label="shift-up">
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => shiftDown(item['id'])} aria-label="shift-down">
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                            <IconButton onClick={() => deleteContentBlock(item['id'], 'editor')} aria-label="delete">
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 row'>
                                                        <div className='col-6 p-1'>
                                                            <input onChange={(evt) => readImg(evt.target.files[0], item['id'], item['img_id'])} className='form-control' id={`img-text-${item['img_id']}`} type='file' accept="image/png, image/jpeg, image/jpg, image/gif" />
                                                            <img src="" data-content-id={`img-text-img-contentid-${item['id']}`} id={`img-text-img-${item['img_id']}`} />
                                                        </div>
                                                        <div className='col-6 p-1'>
                                                            <MyEditor editorState={item['element']} setEditorState={updatedEditorState => {
                                                                const updatedContent = [...additionalContent];
                                                                // updatedContent[idx] = updatedEditorState;
                                                                (updatedContent.find(con => con['id'] === item['id']))['element'] = updatedEditorState
                                                                setAdditionalContent(updatedContent);
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : item['mode'] == 'table' ?
                                                    item['element'] :
                                                    item['mode'] == 'table-data' ?
                                                    
                                                        <div id={`table-card-${item['id']}`} className='my-3 table-card admin-card p-3'>
                                                            Table: #{item['id']}
                                                            <div className='w-100 row'>
                                                                <div className='col-6 d-flex align-items-end'>
                                                                    <h6>Table settings</h6>
                                                                </div>
                                                                <div className='col-6 d-flex justify-content-end'>
                                                                    <Tooltip
                                                                        placement="right"
                                                                        size="small"
                                                                        title={
                                                                            <>
                                                                                <h5 style={{ margin: 0, fontWeight: 'bold', fontSize: '1em' }}>Commands for Table Formatting</h5>
                                                                                <hr style={{ margin: '5px 0' }} />
                                                                                <div>
                                                                                    <span><b>Bold (Ctrl + B)</b></span><br />
                                                                                    <span><i>Italic (Ctrl + I)</i></span><br />
                                                                                    <span><u>Underline (Ctrl + U)</u></span><br />
                                                                                    <span>Superscript (Ctrl + Shift + U): <sup>Text</sup></span><br />
                                                                                    <span>Subscript (Ctrl + Shift + S): <sub>Text</sub></span><br />
                                                                                    <span>Bullet List (Ctrl + Shift + B): <ul style={{ display: 'inline-block', margin: 0 }}><li>Item</li></ul></span><br />
                                                                                    <span>Numbered List (Ctrl + Shift + O): <ol style={{ display: 'inline-block', margin: 0 }}><li>Item</li></ol></span><br />
                                                                                    <span>Left Align (Ctrl +Shift + ArrowLeft)</span><br />
                                                                                    <span>Center Align (Ctrl + Shift + C)</span><br />
                                                                                    <span>Right Align (Ctrl + Shift + ArrowRight)</span><br />
                                                                                    <span>Convert Superscript,Subscript to Normal Text (Ctrl + Shift + M)</span><br />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <IconButton>
                                                                            <InfoIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <IconButton onClick={() => shiftUp(item['id'])} aria-label="shift-up">
                                                                        <ArrowUpwardIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => shiftDown(item['id'])} aria-label="shift-down">
                                                                        <ArrowDownwardIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => deleteContentBlock(item['id'], 'table-data')} aria-label="delete">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            <div className='w-100 mt-4'>
                                                                <div className='col-12 d-flex flex-column admin-card p-3 mb-2'>
                                                                    <div className='d-flex mb-2'>
                                                                        <h6 style={{ fontWeight: 'bold' }}>Table Title</h6>
                                                    
                                                                    </div>
                                                                    <div>
                                                                        <TextField
                                                                            label="Enter Table Header"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={item['tableTitle']}
                                                                            onChange={(event, value) => {
                                                                                handleTableTitle(item['id'], event.target.value);
                                                                            }}
                                                                            size="small"
                                                                        />
                                                                    </div>
                                                                </div>

            

                                                                <div className='admin-card p-3 mb-2'>
                                                                    <div className='col-6 d-flex align-items-center justify-content-between'>
                                                                        <div className='w-50'>
                                                                            <h6 style={{ fontWeight: 'bold' }}>Select Table Style<span className='text-danger'>*</span></h6>
                                                                            <Autocomplete
                                                                                id={`table-select-label-${item['id']}`}
                                                                                options={tableStyle.map((option) => option.table_name)}
                                                                                value={item.tableStyle || tableStyle[0]?.table_name || ''}
                                                                                renderInput={(params) => <TextField {...params} />}
                                                                                onChange={(event, value) => {
                                                                                    handleTableStyleChange(item['id'], value);
                                                                                }}
                                                                                size="small"
                                                                            />
                                                                        </div>
                                                                        <div className='w-50 ml-3'>
                                                                            <h6 style={{ fontWeight: 'bold' }}>Fixed Size</h6>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <IOSSwitch
                                                                                        sx={{ m: 1 }}
                                                                                        checked={!!resizableColumns[item['id']]}
                                                                                        onChange={() => toggleResizableColumns(item['id'])}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                    <div className='d-flex mt-2 align-items-center'>
                                                                        <div className='col-12 d-flex'>
                                                                            <div className='mx-2 col-1'>
                                                                                <div className='d-flex align-items-center' style={{ position: 'relative' }}>
                                                                                    <Tooltip title="Choose header color" arrow>
                                                                                        <div className='ml-2' onClick={() => toggleColorPickerHeader(item['id'])}>
                                                                                            <div  >
                                                                                                <img src={colorPickerHeader} alt="color picker" className='color-picker-img' />
                                                                                            </div>
                                                                                            <div className='picker-button'
                                                                                                style={{
                                                                                                    backgroundColor: colorsHeader[item['id']]?.hex || '#fff',
                                                                                                }}

                                                                                            >
                                                                                            </div>
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                    <div id={`color-picker-${item.id}`} style={{ position: 'absolute', zIndex: 100, top: '4em', left: '100%' }}>
                                                                                        {colorPickerVisibleHeader[item['id']] && (
                                                                                            <SketchPicker
                                                                                                color={colorsHeader[item['id']]?.hex || '#fff'}
                                                                                                onChange={(newColor, event) => {
                                                                                                    event.preventDefault()
                                                                                                    handleColorChangeHeader(item['id'], newColor)

                                                                                                }}
                                                                                                style={{ position: 'absolute', zIndex: 100, left: '50%' }}
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {item['tableStyle'] == 'Striped' &&
                                                                                <div className='mx-2 col-1'>
                                                                                    <div className='d-flex align-items-center' style={{ position: 'relative' }}>
                                                                                        <Tooltip title="Choose Stripe color" arrow>
                                                                                            <div className='ml-2' onClick={() => toggleColorPickerStripes(item['id'])} >
                                                                                                <div  >
                                                                                                    <img src={colorPickerStripes} alt="color picker" className='color-picker-img' />
                                                                                                </div>
                                                                                                <div className='picker-button'
                                                                                                    style={{
                                                                                                        backgroundColor: colorsStripes[item['id']]?.hex || '#fff',

                                                                                                    }}

                                                                                                >
                                                                                                </div>
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                        <div id={`color-picker-stripes-${item.id}`} style={{ position: 'absolute', top: '4em', left: '100%', zIndex: 100 }}>
                                                                                            {colorPickerVisibleStripes[item['id']] && (
                                                                                                <SketchPicker
                                                                                                    color={colorsStripes[item.id]?.hex || '#fff'}
                                                                                                    onChange={(newColor, event) => {
                                                                                                        event.preventDefault()
                                                                                                        handleColorChangeStripes(item['id'], newColor)
                                                                                                    }}
                                                                                                    style={{ position: 'absolute', zIndex: 100, left: '50%' }}
                                                                                                />
                                                                                            )}
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            <div className='col-12 d-flex justify-content-start align-items-center mt-2 mx-2'>
                                                                                <div className="text-align-options">
                                                                                    {renderAlignmentIcon(item['id'])}
                                                                                </div>

                                                                                <div className="table-align-options ml-3">
                                                                                    {renderTableAlignmentIcon(item['id'])}
                                                                                </div>
                                                                                <div className="table-align-options ml-3 ">
                                                                                        {renderTableTitle(item['id'])}
                                                                                    </div>
                                                                                    <div className="table-align-options ml-3">
                                                                                        {renderTableFooter(item['id'])}
                                                                                    </div>
                                                                                    <div className='ml-3'>
                                                                                <div className='d-flex justify-content-end align-items-end flex-column'>
                                                                                    <Tooltip title="Select Font Size" arrow>
                                                                                        <Autocomplete
                                                                                            id="font-size-select"
                                                                                            options={fontSizeOptions.map((option) => option)}
                                                                                            // getOptionLabel={(option) => option.label}  
                                                                                            value={item.fontSize || fontSize[0]?.label || ''}
                                                                                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                                                                                            onChange={(event, newValue) => {
                                                                                                handleFontSizeChange(item['id'], newValue);
                                                                                            }}
                                                                                            size="small"
                                                                                            sx={{ width: '10em' }}

                                                                                        />
                                                                                    </Tooltip>
                                                                                </div>


                                                                            </div>

                                                                            </div>
                                                                            
                                                                        </div>


                                                                    </div>
                                                                    <div className='col-12 admin-card mt-2 '>

                                                                        <div
                                                                            id={`table-card-${item['id']}`}
                                                                            className='my-3 table-card  p-3'
                                                                            style={{ display: 'flex', justifyContent: [item['tableAlignement']] }}
                                                                        >
                                                                            <div className={`table-container-blog-admin table-${item['id']}`}>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <p
                                                                                        style={{
                                                                                            fontWeight: 'bold',
                                                                                            textAlign: item['tabelTitleAlign'],
                                                                                            width: '100%',
                                                                                            fontSize: '1.45rem',
                                                                                            overflowWrap: 'break-word',
                                                                                            wordWrap: 'break-word',
                                                                                            marginBottom:'0.25em'
                                                                                        }}
                                                                                    >
                                                                                        {parse(item['tableTitle'])}
                                                                                    </p>
                                                                                </div>
                                                                                <Table
                                                                                    key={`table-${item['id']}-${item['id'].tableStyle}`}
                                                                                    id={`table-content-${item['id']}`}
                                                                                    columns={item['columns']}
                                                                                    data={item['data']}
                                                                                    tableStyle={item['tableStyle']}
                                                                                    textAlign={textAligns[item['id']] || 'left'}
                                                                                    tableAlignement={tableAligns[item['id']] || 'left'}
                                                                                    headerColors={colorsHeader[item['id']]?.hex || 'white'}
                                                                                    colorsStripes={colorsStripes[item['id']]?.hex || 'rgb(242, 242, 242)'}
                                                                                    resizable={!!resizableColumns[item['id']]}
                                                                                    fontSize={fontSize[item['id']] || '0.75rem'}
                                                                                    headerText={item['headerText']}
                                                                                    stripeText={item['stripeText']}
                                                                                />
                                                                                <div style={{ width: '100%' }}>
                                                                                    <p
                                                                                        style={{
                                                                                            fontWeight: 'bold',
                                                                                            textAlign: item['footerAlign'],
                                                                                            width: '100%',
                                                                                            fontSize: '1.45rem',
                                                                                            overflowWrap: 'break-word',
                                                                                            wordWrap: 'break-word',
                                                                                            marginBottom:'0.25em'
                                                                                        }}
                                                                                    >
                                                                                        {parse(item['tableFooter'])}
                                                                                    </p>
                                                                                </div>                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 d-flex flex-column admin-card p-3 mb-2'>
                                                                    <div className='d-flex mb-2'>
                                                                        <h6 style={{ fontWeight: 'bold' }}>Table Footer</h6>
                                                                     
                                                                    </div>
                                                                    <div>
                                                                        <TextField
                                                                            label="Enter Table Footer"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={item['tableFooter']}
                                                                            onChange={(event, value) => {
                                                                                handleTableFooter(item['id'], event.target.value);
                                                                            }}
                                                                            size="small"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        item['element']
                                    ))
                                }

                                <div className='admin-card p-3 mt-3 d-flex flex-column'>
                                    <div>
                                        <h6 className='text-grey'>Content</h6>
                                        <div className='mt-2 d-flex align-items-center flex-wrap'>
                                            <div onClick={addEditor} className='mx-2 bordered content-button-2 corner p-3'>
                                                <div className='w-60 heading-placeholder mb-3'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <div className='w-100 mt-3 d-flex justify-content-center'>
                                                    <h6 className='bold'>Text</h6>
                                                </div>
                                            </div>
                                            <div onClick={addLink} className='mx-2 bordered content-button-2 corner p-3'>
                                                <div className='w-60 heading-placeholder mb-3'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <button className='blog-button-2 bg-yellow m-0 p-0'><i className="fa-solid fa-link"></i></button>
                                                <div className='w-100 mt-3 d-flex justify-content-center'>
                                                    <h6 className='bold'>Link</h6>
                                                </div>
                                            </div>
                                            <div onClick={addFormButton} className='mx-2 bordered content-button-2 corner p-3'>
                                                <div className='w-60 heading-placeholder mb-3'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <button className='blog-button-2 bg-yellow m-0 p-0'><i className="fa-solid fa-arrow-up-right-from-square"></i></button>
                                                <div className='w-100 mt-3 d-flex justify-content-center'>
                                                    <h6 className='bold'>Form Button</h6>
                                                </div>
                                            </div>
                                            <div onClick={addTableButton} className='mx-2 bordered content-button-2 corner p-3'>
                                                <div className='w-60 heading-placeholder mb-3'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <button className='blog-button-2 bg-yellow m-0 p-0'><i className="fa-solid fa-border-all"></i></button>
                                                <div className='w-100 mt-3 d-flex justify-content-center'>
                                                    <h6 className='bold'>Table</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-3'>
                                        <h6 className='text-grey'>Images</h6>
                                        <div className='mt-2 d-flex align-items-center flex-wrap'>
                                            <div className='mt-2 d-flex align-items-center flex-wrap'>
                                                <div onClick={() => setImgModalHorizontal(true)} className='mx-2 bordered content-button corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='corner bg-light-blue mt-2 p-3 w-100 d-flex justify-content-center align-items-center'>
                                                        <i className="fa-solid fa-image"></i>
                                                    </div>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Horizontal Layout</h6>
                                                    </div>
                                                </div>
                                                <div onClick={() => setImgModal1(true)} className='mx-2 bordered content-button corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-50 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Layout 1</h6>
                                                    </div>
                                                </div>
                                                <div onClick={() => setImgModal2(true)} className='mx-2 bordered content-button corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-40 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-40 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Layout 2</h6>
                                                    </div>
                                                </div>
                                                <div onClick={() => setImgModal3(true)} className='mx-2 my-2 bordered content-button corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                        <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                            <i className="fa-solid fa-image"></i>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 mt-3 d-flex justify-content-center'>
                                                        <h6 className='bold'>Layout 3</h6>
                                                    </div>
                                                </div>
                                                <div onClick={addImageAndText} className='mx-2 my-2 bordered content-button corner p-3'>
                                                    <div className='w-60 heading-placeholder mb-3'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 para-placeholder mb-2'></div>
                                                    <div className='w-100 row'>
                                                        <div className='col-6 d-flex justify-content-center align-items-center'>
                                                            <div className='corner bg-light-blue mt-2 p-3 w-20 d-flex justify-content-center align-items-center'>
                                                                <i className="fa-solid fa-image"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 flex-column d-flex justify-content-center align-items-center'>
                                                            <div className='w-100 para-placeholder mb-2'></div>
                                                            <div className='w-100 para-placeholder mb-2'></div>
                                                            <div className='w-100 para-placeholder mb-2'></div>

                                                        </div>

                                                    </div>
                                                    <div className='w-100 mt-2 d-flex justify-content-center'>
                                                        <h6 className='bold'>Image & Text</h6>
                                                    </div>
                                                </div>
                                                {/* <Button variant='outlined' className='mb-2 mx-1' onClick={addiFrame}>+ iFrame <i className="mx-2 fa-brands fa-youtube"></i></Button> */}
                                            </div>
                                            {/* <Button className='mb-2 mx-1' variant="outlined">+ Horizontal Image <i className="mx-2 fa-solid fa-image"></i></Button> */}
                                        </div>
                                    </div>
                                    <div className='my-1'>
                                        <h6 className='text-grey'>iFrame</h6>
                                        <div className='mt-2 d-flex align-items-center flex-wrap'>
                                            <div onClick={addiFrame} className='bordered content-button corner p-3'>
                                                <div className='w-60 heading-placeholder mb-3'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <div className='w-100 para-placeholder mb-2'></div>
                                                <div className='corner bg-light-blue mt-2 p-3 w-70 d-flex justify-content-center align-items-center'>
                                                    <i className="fa-brands fa-youtube"></i>
                                                </div>
                                                <div className='w-100 mt-3 d-flex justify-content-center'>
                                                    <h6 className='bold'>iFrame</h6>
                                                </div>
                                            </div>
                                            {/* <Button variant='outlined' className='mb-2 mx-1' onClick={addiFrame}>+ iFrame <i className="mx-2 fa-brands fa-youtube"></i></Button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 col-4'>


                                <div className='d-flex flex-column admin-card p-3'>
                                    <Button className='w-100 my-2' variant='outlined' onClick={() => analyzeSEO()}>Analyze SEO</Button>
                                    <Button className='w-100 my-2' variant='outlined' onClick={() => openPreview()}>Preview Blog</Button>
                                    <Button className='w-100 my-2' variant='contained' onClick={() => saveBlog()}>Save Blog</Button>
                                </div>

                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>Blog Author <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="right" size='small' title="Select the author of this blog">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <Select
                                            placeholder="Select Author"
                                            value={selectedAuthor}
                                            closeMenuOnSelect={true}
                                            onChange={(selectedOption) => setSelectedAuthor(selectedOption)}
                                            options={authors.map(author => ({ value: author.authorId, label: author.authorName }))}
                                        />
                                    </div>

                                </div>


                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='w-100 row'>
                                            <div className='col-12 d-flex justify-content-between align-items-end'>
                                                <h6 className='bold text-grey'>Categories <span className='text-danger'>*</span></h6>
                                                <Tooltip placement="right" size='small' title="Select relevant categories for this blog to recommend other suggested blogs to users based on these categories.">
                                                    <IconButton>
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <Select placeholder="Select categories" value={selectedCategories} onChange={opt => {
                                            setSelectedCategories(opt)
                                        }} isMulti closeMenuOnSelect={false} options={categories.map(item => ({ 'label': item, 'value': item }))} className="w-100" />

                                        <div className='w-100 mt-3'>
                                            {
                                                showCustomCategories ?
                                                    <input value={customCategories} onChange={(evt) => setCustomCategories(evt.target.value)} className='my-2 form-control w-100' placeholder='Enter comma separated values' />
                                                    : null
                                            }
                                            <Button onClick={() => setShowCustomCategories(!showCustomCategories)} className='w-100' variant='outlined'>
                                                <i className="mr-2 fa-solid fa-wand-magic-sparkles"></i> {showCustomCategories ? 'Remove custom categories' : 'Add custom categories'}
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>SEO Title <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="right" size='small' title="Title to be displayed in search engine results">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <input value={blogTitle} onChange={(evt) => setBlogTitle(evt.target.value)} className='form-control' type='text' />
                                </div>
                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>Blog URL <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="right" size='small' title="URL is generated from the Blog Title, however it can be changed. Ensure the URL lowercased and hyphen separated with no spaces">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <input value={blogURL} onChange={(evt) => setBlogURL(evt.target.value)} className='form-control' type='text' />
                                </div>
                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>SEO Description <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="top" size='small' title="Description to be displayed in search engine results">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <textarea className='form-control' value={blogDesc} onChange={(evt) => setBlogDesc(evt.target.value)} />
                                </div>
                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>SEO Keywords <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="right" size='small' title="Words or phrases that users type into search engines to find relevant results. Add as many keywords, for web crawlers to efficiently crawl your blog">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <input value={keywords} onChange={(evt) => setKeywords(evt.target.value)} className='form-control' type='text' />
                                </div>
                                <div className='admin-card bg-grey-5 p-3 my-3'>
                                    <div className='w-100 row'>
                                        <div className='col-12 d-flex justify-content-between align-items-end'>
                                            <h6 className='bold text-grey'>Schema Markup <span className='text-danger'>*</span></h6>
                                            <Tooltip placement="left" size='small' title="Provide schema markup in JSON format">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <textarea className='form-control' rows={5} value={schemaMarkup} onChange={(evt) => setSchemaMarkup(evt.target.value)} />
                                </div>

                            </div>
                        </>
                        :
                        <div className='mt-4 w-100 admin-card p-3'>
                            <Alert className='w-100' severity='info'>Choose a template to create a blog.</Alert>
                        </div>
                }

            </section>


            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='lg'
            >
                <section className='w-100 bg-light-blue'>

                    <div className='p-2 d-flex justify-content-center w-100'>
                        {
                            selectedTemplate && open ?
                                <BlogsPreview
                                    editing={false}
                                    author={authors.find(author => author.authorId == selectedAuthor.value)}
                                    inputs={inputValues}
                                    additionalContent={(additionalContent.map(element => processElement(element, 'preview'))).join(' ')}
                                    categories={selectedCategories}
                                    structure={(templates.find(temp => temp['templateId'] == selectedTemplate))['structure']}
                                />
                                :
                                null
                        }
                    </div>
                </section>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={imgModal1}
                onClose={() => setImgModal1(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 1 Image Layout</h3>
                        <p className='text-muted mb-0'>Add a single image</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image File</h6>
                        <input id='img-choose-1-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-1-1-alt-text' placeholder='Enter alternate text' />
                        <Alert className='mt-2 w-100' severity="info">File should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button disabled={!altText ? true : false} size='large' variant='contained' onClick={() => addImageLayout1()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModal2}
                onClose={() => setImgModal2(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 2 Image Layout</h3>
                        <p className='text-muted mb-0'>Add two images, both appearing in a single row</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-40 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image Files</h6>
                        <input id='img-choose-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-2-1-alt-text' placeholder='Enter alternate text' />
                        <input id='img-choose-2' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-2-2-alt-text' placeholder='Enter alternate text' />
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button disabled={!altText ? true : false} size='large' variant='contained' onClick={() => addImageLayout2()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModal3}
                onClose={() => setImgModal3(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <h3 className='bold'>Add 3 Image Layout</h3>
                        <p className='text-muted mb-0'>Add three images, all appearing in a single row</p>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 d-flex justify-content-evenly align-items-center'>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                    <div className='w-20 mx-4 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                        <img className='placeholder-img' src={placeholderImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image Files</h6>
                        <input id='img-choose-3-1' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-3-1-alt-text' placeholder='Enter alternate text' />
                        <input id='img-choose-3-2' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-3-2-alt-text' placeholder='Enter alternate text' />
                        <input id='img-choose-3-3' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-3-3-alt-text' placeholder='Enter alternate text' />
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>

                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button disabled={!altText ? true : false} size='large' variant='contained' onClick={() => addImageLayout3()}>Submit</Button>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={imgModalHorizontal}
                onClose={() => setImgModalHorizontal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>Add Horizontal Image Layout</h3>
                                <p className='text-muted mb-0'>Add an image which spans across the entire width of the page</p>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setImgModalHorizontal(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-3'>
                        <div className='w-100 small-2 border corner'>
                            <div className='blog-preview-header d-flex align-items-center px-3 py-4'>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                                <i class="fa-solid fa-circle text-white mx-1"></i>
                            </div>
                            <div className='p-3'>
                                <div className='w-30 heading-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 para-placeholder mb-3'></div>
                                <div className='w-100 mt-4 bg-blue-2 corner d-flex justify-content-center align-items-center'>
                                    <img className='placeholder-img' src={placeholderImg} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 p-1 d-flex flex-column justify-content-center'>
                        <h6 className='bold'>Choose Image File</h6>
                        <input onChange={handleChange} id='img-choose-horizontal' className={`my-2 form-control`} type="file" accept="image/png, image/jpeg, image/jpg, image/gif" />
                        <input onChange={handleAltChange} className='form-control' type='text' id='img-choose-horizontal-alt-text' placeholder='Enter alternate text' />
                        {!imgValid && document.getElementById('img-choose-horizontal') && document.getElementById('img-choose-horizontal').value ? <small className='text-danger'>File size is more than 500KB!</small> : null}
                        <Alert className='mt-2 w-100' severity="info">Files should be smaller than 500KB. Ensure only jpg, jpeg and png files</Alert>
                    </div>
                    <div className='mt-3 col-12 d-flex justify-content-center align-items-center'>
                        <Button disabled={!imgValid || !altText} size='large' variant='contained' onClick={addImageLayoutHorizontal}>Add Image</Button>
                    </div>
                </section>
            </Dialog>



            <Dialog
                open={analyzeSEOModal}
                onClose={() => setAnalyzeSEOModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>SEO Analysis of selected Categories</h3>
                                <p className='text-muted mb-0'>Analyze the count of occurence of the following keywords based on selected categories.</p>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setAnalyzeSEOModal(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 w-100 d-flex justify-content-center'>
                        <div className='w-80'>
                            {
                                masterKeywords.length ?
                                    <DataGrid
                                        sx={{ borderCollapse: 'collapse', border: 'none' }}
                                        rows={frequencyData}
                                        columns={[
                                            { field: 'id', headerName: 'Keyword', flex: 1, headerClassName: 'bg-grey-3 bold' },
                                            { field: 'count', headerName: 'Count', flex: 1, headerClassName: 'bg-grey-3 bold' },
                                        ]}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 10 },
                                            },
                                        }}
                                        pageSizeOptions={[10, 20, 30, 40]}
                                        checkboxSelection={false}
                                        headerClassName="table-header"
                                    />
                                    :
                                    <Alert severity="warning">No keywords set for selected categories.</Alert>
                            }
                        </div>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={linkTutorial}
                onClose={() => setLinkTutorial(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='sm'
            >
                <section className='w-100 row p-3'>
                    <div className='mt-2 mb-4 col-12'>
                        <div className='w-100 row'>
                            <div className='col-11'>
                                <h3 className='bold'>How to add Hyperlinks in the content</h3>
                            </div>
                            <div className='col-1 d-flex justify-content-end'>
                                <CloseIcon onClick={() => setLinkTutorial(false)} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                    </div>
                    <div className='mb-4 w-100 d-flex justify-content-center'>
                        <div className='w-100'>
                            <p style={{ lineHeight: '2' }}>Wrap the text between placeholders <span className='a-placeholder bold'>##</span>.
                                Enter the text to be displayed and the URL separated by <span className='a-placeholder bold'>__</span>
                            </p>
                            <p>For example,<br />
                                <span className='a-placeholder'>Go to Google__www.google.com</span>
                            </p>
                        </div>
                    </div>
                </section>
            </Dialog>

            <Dialog
                open={saveModal}
                onClose={() => setSaveModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='sm'
            >
                <section className='w-100 row p-3'>
                    <div className='mb-4 w-100 py-4 d-flex flex-column align-items-center justify-content-center'>
                        <CircularProgress />
                        <h3 className='mt-4 bold'>Saving your Blog...</h3>
                        <p className=''>This may take a few seconds.</p>
                    </div>
                </section>
            </Dialog>
        </>
    )
}

export default CreateBlogs;


