import styles from "../../assets/css/road_ahead_talks/ratalks.module.css";
import RANewsletter from "../../assets/img/ra-newsletter-issue.png";
import Form from "../../components/Forms/Form";
import Header from "../../components/common/header";
import Ticker from "../../components/common/ticker";
import RATalksLogo from "./../../assets/img/ra_talks_logo.png";
import JioEventsLogo from "./../../assets/img/jio_events_logo.png";
import SciativeLogo from "./../../assets/img/sciative_logo_white.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ShortStories from "../Blogs/components/ShortStories";
import chats2 from '../../assets/img/chats2.png'
import notes2 from '../../assets/img/notes2.png'
import { GlobalUrl, GlobalImg } from '../../global';
import Contributor from "../../components/common/contributor";

import newsletter from '../../assets/files/road_ahead_newsletter_sep.pdf';

const RoadAheadNewsletter = () => {

    const [guestModal, setGuestModal] = useState(false);

    const subscribe_template2_props = {
        template: 2,
        form_name: "road-ahead-talks-subscribe",
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        },
        bg_color: 'black'
    }

    const subscribe_template3_props = {
        template: 3,
        form_name: "road-ahead-talks-subscribe",
        content_upper: "Sign up for the freshly brewed content on the Road Ahead Talks series",
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const handleFilter = (filter, webinars) => {
        if (filter === "all") {
            setWebinarDisplay({
                startIndex: 0,
                endIndex: webinars.length
            })
        }
        else if (filter === "recent") {
            const endIndex = webinars.length > 3 ? 3 : webinars.length;
            setWebinarDisplay({
                startIndex: 0,
                endIndex: endIndex
            })
        }
        else if (filter === "past") {
            setWebinarDisplay({
                startIndex: 1,
                endIndex: webinars.length
            })
        }
        setFilter(filter);
    }

    const [webinars, setWebinars] = useState([]);
    const [webinarDisplay, setWebinarDisplay] = useState({
        startIndex: 0,
        endIndex: 0
    });
    const [filter, setFilter] = useState("all");

    const getWebinars = async () => {
        try {
            const response = await axios.get(`/ra_talks/fetch_all_webinars`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                const webinars = response.data;
                setWebinars(webinars);
                handleFilter("all", webinars);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                console.log(error.response.data.detail);
            }
            else {
                console.log(error);
                console.log("Unrecognised error occurred. Please try again.");
            }
        }
    }

    useEffect(() => {
        getWebinars();
    }, []);

    const handleRead = async () => {
        window.open(newsletter, '_blank');
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Road Ahead Newsletter | Sciative</title>
                    <link rel="canonical" href={GlobalUrl + '/road-ahead-newsletter/'} />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="csrf_token" content="" />
                    <meta property="type" content="website" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="_token" content="" />
                    <meta name="robots" content="noodp" />
                    <meta name='description' content="Gain invaluable insights from visionary leaders on 'Road Ahead Talks' a captivating webinar series. Discover Bold Perspectives! Watch now to hear experts speak." />
                    <meta name='keywords' content="" />
                    <meta content="image/*" property="og:image:type" />
                </Helmet>

                <div className={styles.ratalks}>

                    <div className={styles.landingSection2}>
                        <div className="container">
                            <div className="row">
                                <div className="mb-4 col-md-7 col-sm-12">
                                    <div className={styles.leftContent}>
                                        <h1 className="bold big-head">Road Ahead Newsletters: Pioneering the Future of Bus Transportation</h1>
                                        <p>Join us as we pioneer the future of bus transportation with exclusive insights and 
                                            transformative case studies. Discover how industry leaders are shaping tomorrow's future, 
                                            leveraging innovation and sustainability to redefine the travel experience.</p>
                                        <div>
                                            <button onClick={handleRead} className="col-sm-12 col-md-3 subscribe-btn">Read Now</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="w-100 d-flex justify-content-center">
                                        <img onClick={handleRead} style={{cursor: 'pointer', height: '20em', width: 'auto'}} src={RANewsletter} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 row d-flex justify-content-center">
                                <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                                    <div className="w-100 d-flex align-items-center justify-content-around">
                                        <h5 className="thin">Knowledge Partner</h5>
                                        <img style={{height: '3em'}} src={SciativeLogo} alt="sciative"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* filter section */}
                    <div className={styles.filterSection}>
                        <ul>
                            <li onClick={() => handleFilter('all', webinars)} className={`${filter === "all" ? styles.activeFilter : ""}`}><span>All Episodes</span></li>
                            <li onClick={() => handleFilter('recent', webinars)} className={`${filter === "recent" ? styles.activeFilter : ""}`}><span>Recent Episodes</span></li>
                            <li onClick={() => handleFilter('past', webinars)} className={`${filter === "past" ? styles.activeFilter : ""}`}><span>Past Episodes</span></li>
                        </ul>
                    </div>

                    <div className={styles.container}>
                        {
                            webinars.slice(webinarDisplay.startIndex, webinarDisplay.endIndex).length === 0 &&
                            <div className={styles.textCenter}>
                                <span>Nothing to show here</span>
                            </div>
                        }
                        <div className={styles.cardContainer}>
                            {
                                webinars.slice(webinarDisplay.startIndex, webinarDisplay.endIndex).map((webinar, index) => (
                                    <Link to={`/road-ahead-talks/${webinar.seo.pageURL}`}>
                                        <div key={index} className={styles.card}>
                                            <div className={styles.imgContainer}>
                                                <img src={webinar['pre-img']['pre-img-key']} alt={webinar['pre-img']['alt']} />
                                            </div>
                                            <div class={styles.contentContainer}>
                                                <h4>{webinar['title']}</h4>
                                                <span>{moment(webinar['publish_date']).format("Do MMMM YYYY")}</span>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </div>

                    {/* guest speaker button */}
                    <div className={styles.GuestBtnContainer}>
                        <button onClick={() => setGuestModal(true)} className={styles.GuestBtn} type="button">
                            Become Our Guest Speaker
                        </button>
                    </div>
                    <div className='p-3 py-1 d-flex flex-sm-column flex-md-row align-items-center mb-5'>
                        <ShortStories />
                    </div>
                    {/* form section */}
                    <Form {...subscribe_template3_props} />

                    <Contributor type='light' />
                    {/* <div className={styles.spacer} /> */}


                    <Modal
                        show={guestModal}
                        onHide={() => setGuestModal(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        contentClassName="remove-white-bg"
                        backdrop="static"
                        centered
                    >
                        <Modal.Body className="p-0">
                            <CloseIcon onClick={() => setGuestModal(false)} className='modal-close-icon' />
                            <Form {...guestSpeakerProps} />
                        </Modal.Body>
                    </Modal>
                </div>
            </HelmetProvider>
        </>
    )

}

export default RoadAheadNewsletter;