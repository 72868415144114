import React, { useMemo, useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useTable, useFlexLayout, useResizeColumns, useSortBy } from "react-table";
import Cell from "./Cell";



const defaultColumn = {
  // minWidth: 50,
  // width: 150,
  // maxWidth: 400,
  Cell: Cell,
  // Header: Header,
  sortType: "alphanumericFalsyLast"
};

export default function Table({ columns, data, dispatch: dataDispatch, skipReset, tableStyle, textAlign, headerColors, colorsStripes, resizable ,fontSize,headerText,stripeText}) {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA, rowB, columnId, desc) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0;
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1;
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1;
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId];
      }
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      dataDispatch,
      autoResetSortBy: !skipReset,
      autoResetFilters: !skipReset,
      autoResetRowState: !skipReset,
      sortTypes
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy
  );

  function isTableResizing() {
    for (let headerGroup of headerGroups) {
      for (let column of headerGroup.headers) {
        if (column.isResizing) {
          return true;
        }
      }
    }

    return false;
  }

  const [columnWidths, setColumnWidths] = useState({});
  const [totalColumns, setTotalColumns] = useState(0);


  useEffect(() => {
    if (headerGroups.length > 0) {
      setTotalColumns(headerGroups[0].headers.length);
      const numColumns = headerGroups[0].headers.length
      const initialColumnWidths = Array(numColumns).fill(100 / numColumns);
      setColumnWidths(initialColumnWidths);
    }
  }, [headerGroups]);

  
  const MIN_WIDTH_PERCENT = 10;


  const handleResize = (index, newWidthPercent, direction) => {
    const updatedColumns = [...columnWidths];
    const originalWidth = updatedColumns[index];
  
    // Check if any column would drop to or below the minimum width after resizing
    const calculateNewWidths = (newWidth) => {
      const tempColumns = [...updatedColumns];
      tempColumns[index] = newWidth;
      // const adjacentIndex = direction === 'left' ? index - 1 : index + 1;
      const adjacentIndex =index + 1;
  
      if (adjacentIndex >= 0 && adjacentIndex < tempColumns.length) {
        const changePercent = newWidth - originalWidth;
        let newAdjacentWidth = tempColumns[adjacentIndex] - changePercent;
        if (newAdjacentWidth < MIN_WIDTH_PERCENT) {
          newWidth = originalWidth + (tempColumns[adjacentIndex] - MIN_WIDTH_PERCENT);
          newAdjacentWidth = MIN_WIDTH_PERCENT;
        }
        tempColumns[index] = newWidth;
        tempColumns[adjacentIndex] = newAdjacentWidth;
      }
  
      return tempColumns;
    };
  
    // Calculate new column widths with the proposed new width
    const tempColumns = calculateNewWidths(newWidthPercent);
  
    // Check if any column width is less than or equal to MIN_WIDTH_PERCENT
    if (tempColumns.some(width => width <= MIN_WIDTH_PERCENT)) {
      return;
    }
  
    // Apply the calculated new widths
    updatedColumns[index] = newWidthPercent;
  
    // Calculate the width change
    const changePercent = newWidthPercent - originalWidth;
    const adjacentIndex = direction === 'left' ? index - 1 : index + 1;
  
    if (adjacentIndex >= 0 && adjacentIndex < updatedColumns.length) {
      let newAdjacentWidth = updatedColumns[adjacentIndex] - changePercent;
      if (newAdjacentWidth < MIN_WIDTH_PERCENT) {
        updatedColumns[index] = originalWidth + (updatedColumns[adjacentIndex] - MIN_WIDTH_PERCENT);
        updatedColumns[adjacentIndex] = MIN_WIDTH_PERCENT;
      } else {
        updatedColumns[adjacentIndex] = newAdjacentWidth;
      }
    }
  
    // Ensure the total width sums up to 100%
    const totalWidth = updatedColumns.reduce((sum, width) => sum + width, 0);
    const widthDiff = 100 - totalWidth;
  
    if (widthDiff !== 0) {
      for (let i = 0; i < updatedColumns.length; i++) {
        if (updatedColumns[i] < MIN_WIDTH_PERCENT) {
          updatedColumns[i] = MIN_WIDTH_PERCENT;
        }
      }
  
      const adjustableColumns = updatedColumns.filter(width => width > MIN_WIDTH_PERCENT);
      const adjustableCount = adjustableColumns.length;
  
      if (adjustableCount > 0) {
        const distributePerColumn = widthDiff / adjustableCount;
  
        updatedColumns.forEach((width, i) => {
          if (width > MIN_WIDTH_PERCENT) {
            updatedColumns[i] += distributePerColumn;
          }
        });
      }
    }
  
    setColumnWidths(updatedColumns);
  };

  let isStriped = tableStyle && tableStyle == "Striped";
  function adjustFontSize(fontSize, scale = 0.8) {
    const match = fontSize.match(/^(\d+(\.\d+)?)\s*(rem|em|px|%)$/);
    
    if (match) {
      const value = parseFloat(match[1]);
      const unit = match[3];
      const newValue = value * scale;
      return `${newValue}${unit}`;
    }
    
    return fontSize;
  }

  const adjustedFontSize = adjustFontSize(fontSize, 0.8);
  return (
    <div
      {...getTableProps()}
      className={clsx("table", isTableResizing() && "noselect")}
      style={{ overflow: "hidden", display: "flex", flexDirection: "column" }}
    >
      <div className="table-header" style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()} className='tr' style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            {headerGroup.headers.map((column, index) => {
              const width = `${columnWidths[index]}%` || `${100 / totalColumns}%`;

              return (
                <div
                  {...column.getHeaderProps({ style: { display: "flex", width, backgroundColor: headerColors,textAlign:textAlign,fontSize:fontSize,color:headerText} })}
                  className='th table-th noselect'
                >
                  <div className="th-content-table" contentEditable
                    // style={{ textAlign: textAlign }}
                    style={{fontSize:'inherit',color:'inherit'}}
                  >
                  </div>
                  {!resizable &&
                    
                    <div
                    {...column.getResizerProps()}
                    className='resizer'
                    style={{
                      // cursor: 'col-resize',
                      position: 'absolute',
                      right: 0,
                      width: '10px',
                      height: '100%',
                      top: 0
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      const startX = e.clientX;
                      const startWidth = e.target.parentElement.offsetWidth;

                      const onMouseMove = (e) => {
                        const newWidth = startWidth + (e.clientX - startX);
                        const parentWidth = e.target.parentElement.parentElement.offsetWidth;
                        const newWidthPercent = (newWidth / parentWidth) * 100;
                        handleResize(index, newWidthPercent);
                      };

                      const onMouseUp = () => {
                        document.removeEventListener("mousemove", onMouseMove);
                        document.removeEventListener("mouseup", onMouseUp);
                      };

                      document.addEventListener("mousemove", onMouseMove);
                      document.addEventListener("mouseup", onMouseUp);
                    }}
                  />

                  }

                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div
        {...getTableBodyProps()}
        className={clsx("table-body", isStriped && "striped-table-body")}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          const rowStyle = isStriped && i % 2 === 1 ? { backgroundColor: colorsStripes } : { backgroundColor: 'white' };
          const bodyStyle = isStriped && i % 2 === 1 ? { color: stripeText } : { color: 'black' };

          return (
            <div {...row.getRowProps()} className='tr' style={{ ...rowStyle, display: "flex", flexDirection: "row", width: "100%" }}>

              {row.cells.map((cell, index) => (
                <div
                  {...cell.getCellProps({
                    style: {
                      width: `${columnWidths[index]}%` || `${100 / totalColumns}%`,
                      overflow: "hidden",
                      backgroundColor: rowStyle.backgroundColor,
                      textAlign:textAlign,
                      fontSize:adjustedFontSize,
                      color:bodyStyle.color
                    }
                  })}
                  className='td table-td'
                >
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      // textAlign:textAlign
                    }}
                  >

                    {cell.render("Cell", 
                      { backgroundColor:'inherit',fontSize:'inherit',color:'inherit'}
                      // { backgroundColor: rowStyle.backgroundColor }
                      
                      )}
                  </div>
                </div>
              ))}


            </div>
          );
        })}
      </div>
    </div>
  );
}