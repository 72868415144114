import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input'
import isValidIndianPhone from '../../services/validators/phoneValidator';
import isValidName from '../../services/validators/nameValidator';
import isValidEmail from '../../services/validators/emailValidator';
import axios from 'axios';
import styles from "../../assets/css/road_ahead_talks/ratalks.module.css";
import "../../assets/css/main.scss";
import DOMPurify from "dompurify";
import ClipLoader from "react-spinners/ClipLoader";


function Form(props) {

    const { country, form_name, fields, title, content_upper, content_lower, recipients_group_id, handleApiResponse, template, text_color, requestedFileKey, bg_color } = props;
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('form_data'));
        if (storedData && Date.now() > storedData.expiry) {
            localStorage.removeItem('form_data');
        }
        else if (storedData) {
            storedData['data']['name'] && setNameField({ ...nameField, value: storedData['data']['name'] });
            storedData['data']['email'] && setEmailField({ ...emailField, value: storedData['data']['email'] });
            storedData['data']['phone'] && setPhoneField({ ...phoneField, value: storedData['data']['phone'] });
            storedData['data']['phonein'] && setPhoneField({ ...phoneField, value: storedData['data']['phonein'] });
            storedData['data']['company'] && setCompanyField({ ...companyField, value: storedData['data']['company'] });
            storedData['data']['topic'] && setTopicField({ ...topicField, value: storedData['data']['topic'] });
            // storedData['data']['companySize'] && setCompanySize({ ...companySize, value: storedData['data']['companySize'] });
            storedData['data']['message'] && setMessage({ ...message, value: storedData['data']['message'] });
        }
    }, [])

    const [nameField, setNameField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [emailField, setEmailField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [companyField, setCompanyField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    // const [companySize, setCompanySize] = useState({
    //     value: "",
    //     valid: true,
    //     error_msg: ""
    // });
    const [phoneField, setPhoneField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [topicField, setTopicField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [message, setMessage] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [sourceField, setSourceField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });
    const [destinationField, setDestinationField] = useState({
        value: "",
        valid: true,
        error_msg: ""
    });

    const validateName = (name) => {
        if (name === "") {
            setNameField({ ...nameField, valid: false, error_msg: "Name is required" });
            return false;
        }
        else if (!isValidName(name)) {
            setNameField({ ...nameField, valid: false, error_msg: "Invalid Name" });
            return false;
        }
        else if (DOMPurify.sanitize(name) !== name) {
            setNameField({ ...nameField, valid: false, error_msg: "Angular brackets are not allowed" });
            return false;
        }
        else {
            setNameField({ ...nameField, valid: true, error_msg: "" });
            return true;
        }
    }

    const validateEmail = (email) => {
        if (email === "") {
            setEmailField({ ...emailField, valid: false, error_msg: "Email is required" });
            return false;
        }
        if (!isValidEmail(email)) {
            setEmailField({ ...emailField, valid: false, error_msg: "Invalid Email" });
            return false;
        }
        const consecutiveDots = /\.\./;
        if (consecutiveDots.test(email)) {
            setEmailField({ ...emailField, valid: false, error_msg: "Consecutive dots are not allowed" });
            return false;
        }          
        if (DOMPurify.sanitize(email) !== email) {
            setEmailField({ ...emailField, valid: false, error_msg: "Angular brackets are not allowed" });
            return false;
        }
        
        setEmailField({ ...emailField, valid: true, error_msg: "" });
        return true;
    }

    const validatePhone = (phone) => {
        if (phone == undefined || phone === "") {
            setPhoneField({ ...phoneField, valid: false, error_msg: "Phone Number is required" });
            return false;
        }
        else if (phone.startsWith("+91")) {
            if (!isValidIndianPhone(phone)) {
                setPhoneField({ ...phoneField, valid: false, error_msg: "Invalid Phone Number" });
                return false;
            }
            else {
                setPhoneField({ ...phoneField, valid: true, error_msg: "" });
                return true;
            }
        }
        else {
            if (!isValidPhoneNumber(phone)) {
                setPhoneField({ ...phoneField, valid: false, error_msg: "Invalid Phone Number" });
                return false;
            }
            else {
                setPhoneField({ ...phoneField, valid: true, error_msg: "" });
                return true;
            }
        }
    }

    const validateCompany = (company) => {
        if (company === "") {
            setCompanyField({ ...companyField, valid: false, error_msg: "Company is required" });
            return false;
        }
        else if (!isValidName(company)) {
            setCompanyField({ ...companyField, valid: false, error_msg: "Invalid Company Name" });
            return false;
        }
        else if (DOMPurify.sanitize(company) !== company) {
            setCompanyField({ ...companyField, valid: false, error_msg: "Angular brackets are not allowed" });
            return false;
        }
        else {
            setCompanyField({ ...companyField, valid: true, error_msg: "" });
            return true;
        }
    }

    // const validateCompanySize = (value) => {
    //     if (!value) {
    //         setCompanySize({ ...companySize, valid: false, error_msg: "Company size is not valid" });
    //         return false
    //     }
    //     else if (value < 1) {
    //         setCompanySize({ ...companySize, valid: false, error_msg: "Company size is not valid" });
    //         return false
    //     }
    //     else {
    //         setCompanySize({ ...companySize, valid: true, error_msg: "" });
    //         return true
    //     }
    // }

    const validateTopic = (topic) => {
        if (topic === "") {
            setTopicField({ ...topicField, valid: false, error_msg: "Topic is required" });
            return false;
        }
        else if (DOMPurify.sanitize(topic) !== topic) {
            setTopicField({ ...topicField, valid: false, error_msg: "Angular brackets are not allowed" });
            return false;
        }
        else {
            setTopicField({ ...topicField, valid: true, error_msg: "" });
            return true;
        }
    }

    const validateMessage = (msg) => {
        if (!msg) {
            setMessage({ ...message, valid: false, error_msg: "Message is required" });
            return false;
        }
        else if (DOMPurify.sanitize(msg) !== msg) {
            setMessage({ ...message, valid: false, error_msg: "Angular brackets are not allowed" });
            return false;
        }
        else {
            setMessage({ ...message, valid: true, error_msg: "" });
            return true;
        }
    }

    const validateSource = (source) => {
        if (!source) {
            setSourceField({ ...sourceField, valid: false, error_msg: "Source is required" });
            return false;
        } else {
            setSourceField({ ...sourceField, valid: true, error_msg: "" });
            return true;
        }
    }

    const validateDestination = (dest) => {
        if (!dest) {
            setDestinationField({ ...destinationField, valid: false, error_msg: "Destination is required" });
            return false;
        } else {
            setDestinationField({ ...destinationField, valid: true, error_msg: "" });
            return true;
        }
    }



    const getRequiredValue = (fieldName) => {
        const field = fields.find(field => field.name === fieldName);
        return field ? field.required : false;
    };

    const storeData = (localStorageData, expiryInDays) => {
        const expiry = Date.now() + expiryInDays * 24 * 60 * 60 * 1000; // Convert days to milliseconds
        const item = {
            data: localStorageData,
            expiry: expiry,
        };
        localStorage.setItem("form_data", JSON.stringify(item));
    }

    const handleSubmit = async () => {
        setLoading(!loading)
        const name = nameField.value.trim();
        const email = emailField.value.trim();
        const phone = phoneField.value;
        const phonein = phoneField.value;
        const company = companyField.value.trim();
        // const compSize = companySize.value;
        const topic = topicField.value.trim();
        const msg = message.value
        const src = sourceField.value.trim()
        const dest = destinationField.value.trim()

        let valid = true;

        if (getRequiredValue("name") && !validateName(name)) valid = false;
        if (getRequiredValue("email") && !validateEmail(email)) valid = false;
        if (getRequiredValue("phone") && !validatePhone(phone)) valid = false;
        if (getRequiredValue("phonein") && !validatePhone(phonein)) valid = false;
        if (getRequiredValue("company") && !validateCompany(company)) valid = false;
        if (getRequiredValue("topic") && !validateTopic(topic)) valid = false;
        // if (getRequiredValue("companySize") && !validateCompanySize(compSize)) valid = false;
        if (getRequiredValue("message") && !validateMessage(msg)) valid = false;
        if (getRequiredValue("source") && !validateSource(src)) valid = false;
        if (getRequiredValue("destination") && !validateDestination(dest)) valid = false;

        if (valid) {
            const localStorageData = {}
            getRequiredValue("name") && (localStorageData["name"] = nameField.value);
            getRequiredValue("email") && (localStorageData["email"] = emailField.value);
            getRequiredValue("phone") && (localStorageData["phone"] = phoneField.value);
            getRequiredValue("phonein") && (localStorageData["phonein"] = phoneField.value);
            getRequiredValue("company") && (localStorageData["company"] = companyField.value);
            getRequiredValue("topic") && (localStorageData["topic"] = topicField.value);
            // getRequiredValue("companySize") && (localStorageData["companySize"] = compSize);
            getRequiredValue("message") && (localStorageData["message"] = message.value);
            storeData(localStorageData, 1);

            const postData = {
                form_name: form_name,
                fields: fields,
                recipients_group_id: recipients_group_id,
                data: {
                    name: DOMPurify.sanitize(name),
                    email: DOMPurify.sanitize(email),
                    phone: DOMPurify.sanitize(phone),
                    phonein: DOMPurify.sanitize(phonein),
                    company: DOMPurify.sanitize(company),
                    topic: DOMPurify.sanitize(topic),
                    // companySize: DOMPurify.sanitize(compSize),
                    message: DOMPurify.sanitize(msg),
                    source: DOMPurify.sanitize(src),
                    destination: DOMPurify.sanitize(dest)
                },
                page: window.location.pathname + window.location.search,
                requestedFileKey: requestedFileKey
            }
            try {
                const response = await axios.post(`/submit_form`, postData, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (response.status === 200 && response.data !== undefined) {
                    handleApiResponse({
                        status: "success",
                        data: response.data
                    })
                    setLoading(false)
                }      
            } catch (error) {

                if (error.response && error.response.data) {
                    handleApiResponse({
                        status: "error",
                        data: error.response.data.message
                    })
                    setLoading(false)
                }
                else {
                    console.log(error)
                    alert("Unrecognised error occurred. Please try again.");
                    setLoading(false)
                }
            }
        }

        else {
            toast.dismiss()
            toast.error('Invalid input! Please enter a valid input.');
            setLoading(false)
        }
        
    }

    return (
        <>
            {template === 1 &&
                <div className='demo-form w-100 row p-3 m-0'>
                    <div className='col-md-6'>
                        <div>
                            <h3 className='get-in-touch text-white'>
                                {title}
                            </h3>
                            <p className='thin mt-4 text-white'>
                                {content_upper}
                            </p>
                            <p className='text-yellow bold'>
                                {content_lower}
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6 d-flex flex-column'>
                        {
                            fields.map((field, index) => {
                                switch (field.name) {
                                    case "name":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Full Name <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='w-100 demo-request-input'
                                                    placeholder='John Doe'
                                                    value={nameField.value}
                                                    onChange={(e) => setNameField({ ...nameField, value: e.target.value })}
                                                />
                                                {!nameField.valid ? <small className='text-danger'>{nameField.error_msg}</small> : null}
                                            </div>
                                        );

                                    case "email":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Email Address <span className='text-danger'>*</span></label>
                                                <input
                                                    value={emailField.value}
                                                    type='email'
                                                    className='w-100 demo-request-input'
                                                    placeholder='john@gmail.com'
                                                    onChange={(e) => setEmailField({ ...emailField, value: e.target.value })}
                                                />
                                                {!emailField.valid ? <small className='text-danger'>{emailField.error_msg}</small> : null}
                                            </div>
                                        );

                                    case "phone":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Phone Number <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    international
                                                    defaultCountry={country ? country : "US"}
                                                    value={phoneField.value}
                                                    onChange={(phone) => setPhoneField({ ...phoneField, value: phone })}
                                                />
                                                {!phoneField.valid ? <small className='text-danger'>{phoneField.error_msg}</small> : null}

                                            </div>
                                        );
                                    case "phonein":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Phone Number <span className='text-danger'>*</span></label>
                                                <PhoneInput
                                                    international
                                                    defaultCountry={country ? country : "US"}
                                                    value={phoneField.value}
                                                    onChange={(phone) => setPhoneField({ ...phoneField, value: phone })}
                                                />
                                                {!phoneField.valid ? <small className='text-danger'>{phoneField.error_msg}</small> : null}

                                            </div>
                                        );

                                    case "company":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Company Name <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='w-100 demo-request-input'
                                                    value={companyField.value}
                                                    onChange={(e) => setCompanyField({ ...companyField, value: e.target.value })}
                                                />
                                                {!companyField.valid ? <small className='text-danger'>{companyField.error_msg}</small> : null}
                                            </div>
                                        );

                                    case "topic":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Proposed Topic <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='w-100 demo-request-input'
                                                    value={topicField.value}
                                                    onChange={(e) => setTopicField({ ...topicField, value: e.target.value })}
                                                />
                                                {!topicField.valid ? <small className='text-danger'>{topicField.error_msg}</small> : null}
                                            </div>
                                        );
                                    case "message":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Message <span className='text-danger'>*</span></label>
                                                <textarea
                                                    className='w-100 demo-request-input'
                                                    value={message.value}
                                                    onChange={(e) => setMessage({ ...message, value: e.target.value })}
                                                />
                                            </div>
                                        )
                                    case "source":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Source <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='w-100 demo-request-input'
                                                    placeholder=''
                                                    value={sourceField.value}
                                                    onChange={(e) => setSourceField({ ...sourceField, value: e.target.value })}
                                                />
                                                {!sourceField.valid ? <small className='text-danger'>{sourceField.error_msg}</small> : null}
                                            </div>
                                        )
                                    case "destination":
                                        return (
                                            <div key={index} className='my-2'>
                                                <label className='form-label text-white'>Destination <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='w-100 demo-request-input'
                                                    placeholder=''
                                                    value={destinationField.value}
                                                    onChange={(e) => setDestinationField({ ...destinationField, value: e.target.value })}
                                                />
                                                {!destinationField.valid ? <small className='text-danger'>{destinationField.error_msg}</small> : null}
                                            </div>
                                        )
                                }
                            })
                        }
                        <div className='my-3'>
                            <button onClick={handleSubmit} className='form-button'>
                                {loading ? (
                                    <ClipLoader color={"#ffffff"} size={15} />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            }

            {
                template === 2 &&
                <div className="w-100 row gap-3 m-auto">
                    <input
                        style={bg_color === 'black' ? { backgroundColor: 'black' } : {}}
                        className={`col-sm-12 col-md-8 p-2 ps-4 ${text_color === 'text-black' ? 'text-black' : ''} input-box ${!emailField.valid ? "is-invalid" : ""}`}
                        type="text"
                        value={emailField.value}
                        onChange={(e) => setEmailField({ ...emailField, value: e.target.value })}
                        placeholder="Enter your email address" />
                    <button onClick={handleSubmit} className="col-sm-12 col-md-3 subscribe-btn">
                        {loading ? (
                            <ClipLoader color={"#ffffff"} size={15} />
                        ) : (
                            'Subscribe'
                        )}
                    </button>
                </div>
            }

            {
                template === 3 &&
                <div className={styles.formContainer}>
                    <div className={styles.leftContent}>
                        <h2>
                            {content_upper}
                        </h2>
                    </div>
                    <div className={styles.formRight}>
                        <input
                            placeholder="Business email*"
                            type="text"
                            value={emailField.value}
                            onChange={(e) => setEmailField({ ...emailField, value: e.target.value })}
                        />
                            <button type="button" onClick={handleSubmit}>
                                {loading ? (
                                    <ClipLoader color={"#ffffff"} size={15} />
                                ) : (
                                    'Subscribe'
                                )}
                            </button>
                    </div>
                </div>
            }

            {
                template === 4 &&
                <div className='w-100 row contact-form m-0'>
                    {
                        fields.map((field, index) => {
                            switch (field.name) {
                                case "name":
                                    return (
                                        <div className='my-2 col-12'>
                                            <input
                                                className='w-100 contact-input ps-3 py-md-4 py-2 py-sm-2'
                                                type='text'
                                                placeholder='Full Name*'
                                                value={nameField.value}
                                                onChange={(e) => setNameField({ ...nameField, value: e.target.value })}
                                            />
                                            {!nameField.valid ? <small className='text-danger'>{nameField.error_msg}</small> : null}
                                        </div>
                                    );

                                case "email":
                                    return (
                                        <div className='my-2 col-12 row p-0 m-0'>
                                            <div className='col-6'>
                                                <input
                                                    className='w-100 contact-input ps-3 py-md-4 py-2 py-sm-2'
                                                    type='text'
                                                    placeholder='Business Email*'
                                                    value={emailField.value}
                                                    onChange={(e) => setEmailField({ ...emailField, value: e.target.value })}
                                                />
                                                {!emailField.valid ? <small className='text-danger'>{emailField.error_msg}</small> : null}
                                            </div>
                                            <div className='col-6'>
                                                <input
                                                    className='w-100 contact-input ps-3 py-md-4 py-2 py-sm-2'
                                                    type='text'
                                                    placeholder='Company Name*'
                                                    value={companyField.value}
                                                    onChange={(e) => setCompanyField({ ...companyField, value: e.target.value })}
                                                />
                                                {!companyField.valid ? <small className='text-danger'>{companyField.error_msg}</small> : null}
                                            </div>
                                        </div>
                                    );

                                case "phone":
                                    return (
                                        <div className='my-2 col-12 custom-phoneInput'>
                                            <PhoneInput
                                                className='w-100 p-2'
                                                international
                                                defaultCountry={country ? country : "US"}
                                                value={phoneField.value}
                                                onChange={(phone) => setPhoneField({ ...phoneField, value: phone })}
                                            />
                                            {!phoneField.valid ? <small className='text-danger'>{phoneField.error_msg}</small> : null}
                                        </div>
                                    );
                                case "phonein":
                                    return (
                                        <div className='my-2 col-12 custom-phoneInput'>
                                            <PhoneInput
                                                className='w-100 p-2'
                                                international
                                                defaultCountry={country ? country : "US"}
                                                value={phoneField.value}
                                                onChange={(phone) => setPhoneField({ ...phoneField, value: phone })}
                                            />
                                            {!phoneField.valid ? <small className='text-danger'>{phoneField.error_msg}</small> : null}
                                        </div>
                                    );
                                // case "companySize":
                                //     return (
                                //         <div className='my-2 col-12'>
                                //             <input
                                //                 className='w-100 contact-input ps-3 ps-3 py-md-4 py-2 py-sm-2'
                                //                 type='number'
                                //                 placeholder='Company Size*'
                                //                 value={companySize.value}
                                //                 onKeyDown={(e) => (e.key==='e' || e.key==='E') && e.preventDefault()}
                                //                 onChange={(e) => setCompanySize({ ...companySize, value: e.target.value })}
                                //             />
                                //             {!companySize.valid ? <small className='text-danger'>{companySize.error_msg}</small> : null}
                                //         </div>
                                //     );

                                case "message":
                                    return (
                                        <div className='my-2 col-12'>
                                            <textarea
                                                className='w-100 contact-textarea ps-3 p-2'
                                                placeholder='Your Message*'
                                                value={message.value}
                                                onChange={(e) => setMessage({ ...message, value: e.target.value })}
                                            />
                                            {!message.valid ? <small className='text-danger'>{message.error_msg}</small> : null}
                                        </div>
                                    );
                            }
                        })
                    }
                        <div className='col-12'>
                            <button onClick={handleSubmit} style={{ fontSize: 'max(1rem, 1.2vw)' }} className='subscribe-btn w-100 mt-2 py-md-3 py-sm-2'>
                                {loading ? (
                                    <ClipLoader color={"#ffffff"} size={15} />
                                ) : (
                                    'Contact Us'
                                )}
                            </button>
                        </div>
                </div>
            }
            {
                template === 5 &&
                <div className='w-100 row contact-form m-0'>
                    <h6 className="fw-bold mb-2 text-center">Contact Pricing Experts Now !</h6>
                    {
                        
                        fields.map((field, index) => {
                            
                            switch (field.name) {
                                case "name":
                                    return (
                                        <div className='my-1 col-6'>
                                            <input
                                                className='w-100 contact-black-input ps-3 py-md-2 py-2 py-sm-2'
                                                type='text'
                                                placeholder='Full Name*'
                                                value={nameField.value}
                                                onChange={(e) => setNameField({ ...nameField, value: e.target.value })}
                                            />
                                            {!nameField.valid ? <small className='text-danger'>{nameField.error_msg}</small> : null}
                                        </div>
                                    );

                                case "email":
                                    return (
                                        <div className='my-1 col-6 row p-0 m-0'>
                                            <div className='col-12'>
                                                <input
                                                    className='w-100 contact-black-input ps-3 py-2'
                                                    type='text'
                                                    placeholder='Business Email*'
                                                    value={emailField.value}
                                                    onChange={(e) => setEmailField({ ...emailField, value: e.target.value })}
                                                />
                                                {!emailField.valid ? <small className='text-danger'>{emailField.error_msg}</small> : null}
                                            </div>
                                        </div>
                                    );

                                case "phone":
                                    return (
                                        <div className='my-1 col-6'>
                                            <PhoneInput
                                                className='w-100 py-2 PhoneInputInput-black'
                                                // style={{backgroundColor: "#181819", color:"white"}}
                                                international
                                                defaultCountry={country ? country : "US"}
                                                value={phoneField.value}
                                                onChange={(phone) => setPhoneField({ ...phoneField, value: phone })}
                                            />
                                            {!phoneField.valid ? <small className='text-danger'>{phoneField.error_msg}</small> : null}
                                        </div>
                                    );
                                
                                case "company":
                                    return (   
                                        <div className='my-1 col-6 row py-0 m-0'>
                                            <div className='col-12 px-0'>
                                                    <input
                                                        className='w-100 contact-black-input ps-3 py-2 mt-2'
                                                        type='text'
                                                        placeholder='Company Name*'
                                                        value={companyField.value}
                                                        onChange={(e) => setCompanyField({ ...companyField, value: e.target.value })}
                                                    />
                                                    {!companyField.valid ? <small className='text-danger'>{companyField.error_msg}</small> : null}
                                            </div></div>
                                        )
                                case "message":
                                    return (
                                        <div className='my-1 col-12'>
                                            <textarea
                                                className='w-100 contact-black-textarea ps-3 p-2'
                                                placeholder='Your Message*'
                                                value={message.value}
                                                onChange={(e) => setMessage({ ...message, value: e.target.value })}
                                            />
                                            {!message.valid ? <small className='text-danger'>{message.error_msg}</small> : null}
                                        </div>
                                    );
                            }
                        })
                    }
                        <div className='row pe-0'>
                            <div className='col-7-5 pe-0 m-0 black-input'>
                                <p>Connect to Experts With 25+ Years of Pricing Experience</p>
                            </div>
                            <div className='col-3-5 pe-0'>
                                <button 
                                    onClick={handleSubmit} 
                                    style={{ fontSize: 'clamp(0.6rem, 1.2vw, 1rem)' }} 
                                    className='subscribe-btn w-100 mt-0 py-md-2 py-sm-2'>
                                    {loading ? (
                                        <ClipLoader color={"#ffffff"} size={15} />
                                    ) : (
                                        'Contact Experts'
                                    )}
                                </button>
                            </div>
                        </div>
                </div>
            }
        </>
    );
}

export default Form;