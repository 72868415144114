import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Awards from '../travel/capterra-achievvement';
import profilePic from '../../assets/img/dr-anshu-jalora.jpeg';
import logo from '../../assets/img/sciative-logo.png';
import open from '../../assets/img/open.png';
import captera from '../../assets/img/travel-award/captera.png'
import getapp from '../../assets/img/travel-award/get-app.png'
import softwareadvice from '../../assets/img/travel-award/software-advice.png'
import QRC from '../../assets/img/travel-award/QRC_logo.png'

const RetailStaticPage = () => {

    return (
        <div className='retail-static-page'>
            <section className='bg-white w-100'>
                <div className='bg-black p-1'>
                    <div className='container d-flex justify-content-between align-items-center'>
                        <a className='text-white medium-2' href='/retail'><i className="mr-2 fa-solid fa-house"></i>Home</a>
                        <img className='retail-static-page-logo' src={logo} />
                    </div>
                </div>
                <div className='bg-yellow'>
                    <div className='container px-3 py-4 d-flex flex-column justify-content-center align-items-center'>
                        <h1 className='retail-static-page-header bold text-center'>SCIATIVE - WE PRICE RIGHT</h1>
                        <h2 className='retail-static-page-sub-header thin text-center'>POWER UP RETAIL PRICING</h2>
                        <h6 style={{ fontSize: '1.05em' }} className='retail-static-page-sub-heading bold text-center mt-3'>Achieve 5-10% increase in gross profit, increase revenue sustainably & improve customer value perception with Sciative</h6>
                    </div>
                </div>
                <div className='bg-white'>
                    <div className='container px-3 py-4 d-flex flex-column justify-content-center align-items-center'>
                        <h5 className='retail-static-page-sub-heading text-center bold mt-2'>Sciative is an end-to-end Pricing Platform for E-Commerce, Brick-and-Mortar Retailers
                            and Brands - to streamline and optimize pricing strategies.
                            We are a native cloud, system-agnostic, AI-Powered SaaS solution.</h5>

                        <div className='w-100 mt-4 p-3 corner bg-black'>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} lg={2.37}>
                                        <div className='p-2'>
                                            <h5 className='large text-center bold text-yellow'>2018</h5>
                                            <p className='small mb-0 text-center text-white mt-2 thin'>Founded</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2.37}>
                                        <div className='p-2'>
                                            <h5 className='large text-center bold text-yellow'>100 +</h5>
                                            <p className='small mb-0 text-center text-white mt-2 thin'>Data Scientists & Engineers</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2.37}>
                                        <div className='p-2'>
                                            <h5 className='large text-center bold text-yellow'>567 +</h5>
                                            <p className='small mb-0 text-center text-white mt-2 thin'>Satisfied Clients</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2.37}>
                                        <div className='p-2'>
                                            <h5 className='large text-center bold text-yellow'>5 Billion +</h5>
                                            <p className='small text-center text-white mt-2 thin'>Daily Price Optimisations</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2.37}>
                                        <div className='p-2'>
                                            <h5 className='large text-center bold text-yellow'>32 Days</h5>
                                            <p className='small mb-0 text-center text-white mt-2 thin'>Guaranteed Results</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div className='mt-2'>
                            <div className='mt-4 w-100 d-flex justify-content-center align-items-center'><h6 className='bold text-muted'>Client Testimonials</h6></div>
                            <img src={open} style={{ height: '7em' }} />
                            <div style={{ position: "relative", top: '-2.8em' }}>
                                <p className='retail-static-page-testimonial medium-2 thin mb-0'>
                                    “We faced significant challenges with price parity among our sellers. Our attempts to build
                                    an in- house solution fell short compared to Sciative's automated brand health solution.
                                    Their expertise has effectively resolved our price parity issues and significantly improved
                                    consistency across our products.”
                                </p>
                                <p className='mt-1 bold'>Global Program Manager, Multi-brand retailer</p>
                            </div>
                        </div>
                        <div className=''>
                            <p className='retail-static-page-testimonial medium-2 thin mb-0'>
                                “We needed a partner who could provide deep insights into our retail landscape and offer
                                the flexibility to create tailored solutions for pricing decisions. Sciative deployed their
                                platform which helped us build tailored pricing strategies and discounting that perfectly
                                matched our business needs.”
                            </p>
                            <p className='mt-1 bold'>Chief of Technology, Global Beauty Retailer</p>
                        </div>

                        <div className='my-3 w-100'>
                            <div className='w-100 bg-black p-3' style={{ borderRadius: '200px' }}>
                                <p className='bold text-yellow text-center mb-0'>Founded by Dr. Anshu Jalora – Top Voice in Pricing Strategy (2024)</p>
                            </div>
                        </div>
                        <div className='mt-3 mb-5 row'>
                            <div className='retail-static-page-profile-col col-md-6 offset-md-3 offset-lg-0 col-lg-4 col-sm-12'>
                                <div style={{
                                    backgroundImage: `url(${profilePic})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    borderTopLeftRadius: '12px',
                                    borderTopRightRadius: '12px',
                                    content: ''
                                }}
                                    className='retail-static-page-profile'>
                                    .
                                </div>
                                <div className='bg-black p-3' style={{
                                    borderBottomLeftRadius: '12px',
                                    borderBottomRightRadius: '12px'
                                }}>
                                    <h4 className='text-yellow bold text-center'><a className='text-yellow retail-static-page-link' target='_blank' href='//linkedin.com/in/anshujalora/'>Dr. Anshu Jalora</a></h4>
                                    <p className='mb-0 thin text-center text-white'>Founder, Sciative Solutions</p>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-8 p-2 col-sm-12 d-flex flex-column justify-content-around'>
                                <p className='retail-static-page-profile-text thin'>Dr. Anshu Jalora is a renowned pricing thought leader with
                                    over 26 years of experience in driving pricing transformation for leading global consumer and corporate
                                    brands.</p>
                                <p className='retail-static-page-profile-text mt-3 thin'>Prior to founding Sciative, he held key positions including
                                    Global Director of Pricing Strategy at Starbucks (USA),
                                    Director of Pricing at Overstock.com (USA), and Senior
                                    Pricing Scientist at PROS Revenue Management.</p>
                                <p className='retail-static-page-profile-text mt-3 thin'>Dr. Jalora graduated top of his class from IIT Delhi, one of
                                    India's premier technology institutions, and earned his
                                    Ph.D. from Texas A&M University, USA.
                                    He holds a patent in pricing software and has authored
                                    over 17 international publications.</p>

                                <div className='mt-2 w-50 d-flex flex-column'>
                                    <p><i className="mr-2 fa-regular fa-envelope"></i>anshu@sciative.com</p>
                                    <p><i className="mr-2 fa-solid fa-mobile-screen"></i>+91 9167444988</p>
                                </div>
                                <div className='mt-2 w-40 d-flex justify-content-between align-items-center'>
                                    <a className='sm-links large-3 text-black' target="_blank" href='//linkedin.com/company/sciative-solutions-private-limited/mycompany/'><i className="fa-brands fa-linkedin"></i></a>
                                    <a className='sm-links large-3 text-black' target="_blank" href='//facebook.com/profile.php?id=100085304680567'><i className="fa-brands fa-facebook"></i></a>
                                    <a className='sm-links large-3 text-black' target="_blank" href='//instagram.com/sciative_solutions/?igsh=ZzF0c2tiMGtqYTFq'><i className="fa-brands fa-instagram"></i></a>
                                    <a className='sm-links large-3 text-black' target="_blank" href='//x.com/Sciative'><i className="fa-brands fa-x-twitter"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container award-section">
                    <hr />
                    <h3 className="bold text-center mb-5 ">Recognised by the Best in the Industry</h3>
                    <div className="row">
                        <div className=" col-md-6 col-xl-3">
                            <div className="recog_img_div">
                                <img src={getapp} alt="GetApp Logo" />
                                <p className=" award_desc">Best Functionality & Features</p>

                                <div className="">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <div className="">
                                    <p className="award_name">GetApp 2023</p>
                                </div>

                            </div>
                        </div>
                        {/* col-12 col-md-6 col-xl-3 end */}


                        <div className=" col-md-6 col-xl-3">
                            <div className="recog_img_div">
                                <img src={softwareadvice} alt="Software Advice Logo" />
                                <p className="award_desc">Best Customer Support <br /> | Most Recommended</p>

                                <div className=" ">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <div className="">
                                    <p className="award_name">Software Advice 2023</p>
                                </div>

                            </div>
                        </div>

                        {/* col-12 col-md-6 col-xl-3 end */}
                        <div className=" col-md-6 col-xl-3">
                            <div className="recog_img_div">
                                <img src={captera} alt="Captera Logo" />
                                <p className="award_desc">Best Ease of Use | Best Value </p>
                                <div className=" ">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <div className="">
                                    <p className="award_name">Capterra 2023</p>
                                </div>

                            </div>
                        </div>
                        {/* col-12 col-md-6 col-xl-3 end */}
                        {/* col-12 col-md-6 col-xl-3 end */}
                        <div className=" col-md-6 col-xl-3">
                            <div className="recog_img_div">
                                <img src={QRC} alt="QRC Logo" />
                                <p className="award_desc">Certified for Web Application Security Testing</p>

                                <div className=" ">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                </div>
                                <div className="">
                                    <p className="award_name">QRC 2024</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-black p-3 mt-5'>
                    <div className='container'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-md-4 col-lg-7 col-sm-12 d-flex justify-content-end align-items-center retail-static-page-contact-div'>
                                <a href='/contact' className='contact-btn py-2 px-3 text-center btn-yellow corner text-black mb-0'>Contact Us</a>
                            </div>
                            <div className='retail-static-page-loc col-md-8 col-lg-5 col-sm-12 row d-flex align-items-center'>
                                <div className='col-4 d-flex align-items-center justify-content-center' style={{ borderRight: '1.5px solid #fff' }}><p className='mb-0 bold text-yellow'><span className='text-white'><i className="mr-2 fa-solid fa-location-dot"></i></span>India</p></div>
                                <div className='col-4 d-flex align-items-center justify-content-center' style={{ borderRight: '1.5px solid #fff' }}><p className='mb-0 bold text-yellow'><span className='text-white'><i className="mr-2 fa-solid fa-location-dot"></i></span>USA</p></div>
                                <div className='col-4 d-flex align-items-center justify-content-center'><p className='mb-0 bold text-yellow'><span className='text-white'><i className="mr-2 fa-solid fa-location-dot"></i></span>Australia</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RetailStaticPage;