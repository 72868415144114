import styles from "../../assets/css/road_ahead_talks/webinar.module.css";
import Header from "../../components/common/header";
import Ticker from "../../components/common/ticker";
import SciativeLogo from "../../assets/img/sciative_logo_black.png";
import RATalksLogo from "../../assets/img/ratalks_logo_small.png";
import JioEventsLogo from "../../assets/img/jio_events_logo_black.png";
import RATalksBlack from "../../assets/img/ra_talks_black.png";
import SpeakerImg from "../../assets/img/speaker_1.png";
import WebinarImg from "../../assets/img/webinar.png";
import { useEffect, useState } from "react";
import Footer from "../../components/common/footer";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Countdown from "../../components/Countdown/Countdown";
import Form from "../../components/Forms/Form";
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { HelmetProvider, Helmet } from "react-helmet-async";

function Webinar() {

    const [guestModal, setGuestModal] = useState(false);
    const guestSpeakerProps = {
        template: 1,
        form_name: "become-a-guest-speaker",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become Our Guest Speaker",
        content_upper: "Got an amazing story to share? Become a featured guest on our podcast and share your insights with our audience. Submit your topic idea and let's make magic together on air.",
        content_lower: "Your voice could reach thousands of eager listeners!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }
    const guestWriterProps = {
        template: 1,
        form_name: "become-a-guest-writer",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "topic", required: true }
        ],
        title: "Become a Guest Writer",
        content_upper: "Got a great idea? We want to hear it! Submit your blog topic here and become a valued contributor to our site.",
        content_lower: "Your insights could reach thousands!",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setGuestModal(false);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }
    const videoRequestProps = {
        template: 1,
        form_name: "road-ahead-talks-video",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "Register Now!",
        content_upper: "Discover the pulse of the industry and gain invaluable insights from top minds as we navigate the 'Road Ahead Talks' in travel, retail, and hospitality on this captivating webinar series.",
        content_lower: "Fill out form to watch the webinar.",
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            if (res && res.status && res.status === "success") {
                console.log(res.data.message.video_links);
                const video_links = res.data.message.video_links;
                console.log(video_links.youtube_link);
                console.log(video_links.spotify_link);
                setVideoLinks({
                    youtube: video_links.youtube_link,
                    spotify: video_links.spotify_link
                });
                setShowModalVideoRequest(false);
                setShowModalIframe(true);
                toast.success('Form submitted successfully!');
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const { url } = useParams();

    const [webinar, setWebinar] = useState(null);
    const [showIframe, setShowIframe] = useState(false);
    const [pastWebinar, setPastWebinar] = useState(false);
    const [selectedExpert, setSelectedExpert] = useState(null);

    const [showLoading, setShowLoading] = useState(true);

    const [showModalVideoRequest, setShowModalVideoRequest] = useState(false);
    const [videoLinks, setVideoLinks] = useState({
        youtube: null,
        spotify: null
    });

    const [showModalIframe, setShowModalIframe] = useState(false);


    const getWebinar = async (url) => {
        try {
            const response = await axios.get(`/ra_talks/get_talk/${url}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                const webinar = response.data;
                setWebinar(webinar);
                setSelectedExpert(webinar['featured_experts'][0])

                const currentDateTime = new Date();
                // show iframe decision
                if (webinar['reg_link'] != undefined && webinar['reg_link'] != "" && webinar['reg_link'] != null) {
                    console.log('link_end_time');
                    console.log(webinar['link_end_time']);
                    const link_end_date_time = new Date(`${webinar['publish_date']}T${webinar['link_end_time']}:00`);
                    link_end_date_time > currentDateTime && setShowIframe(true);
                }

                // past webinar decision
                const publish_date_time = new Date(`${webinar['publish_date']}T${webinar['publish_time']}:00`);
                !(publish_date_time > currentDateTime) && setPastWebinar(true);

                setShowLoading(false);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getWebinar(url);
    }, [])


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    {
                        webinar &&
                        <>
                            <title>{webinar['seo']['page_title']}</title>
                            <meta charset="utf-8" />
                            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                            <meta name="csrf_token" content="" />
                            <meta property="type" content="website" />
                            <meta name="theme-color" content="#ffffff" />
                            <meta name="_token" content="" />
                            <meta name="robots" content="noodp" />
                            <meta name='description' content={webinar['seo']['meta_desc']} />
                            <meta name='keywords' content={webinar['seo']['meta_keywords'].join(', ')} />
                            <meta property="og:locale" content="en_US" />
                            <meta property="og:title" content="Sciative" />
                            <meta property="og:hashtag" content="#ai" />
                            <meta content="image/*" property="og:image:type" />
                            <meta property="og:url" content='https://sciative.com' />
                            <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                        </>
                    }
                </Helmet>
                <div className={styles.webinar}>

                    {/* ticker & navbar */}
                    <Ticker props={{ text: "This is a dummy para", link: "https://www.google.com", linkText: "Listen now!", targets: "blank", tPosition: "sticky_ticker", fixed: true }} />
                    <Header fixed={true} />

                    {
                        showLoading ?
                            // loading section
                            <div className={styles.loadingSection}>
                                <img src={RATalksBlack} alt="road ahead talks" />
                            </div>
                            :
                            <>
                                {/* landing section */}
                                <div className={styles.landing}>

                                    {/* header section */}
                                    <div className={`${styles.header} container`}>
                                        <div className={styles.leftLogo}>
                                            <img src={SciativeLogo} alt="sciative" />
                                            <div className={styles.horizontalDivider}></div>
                                            <img src={RATalksLogo} alt="road ahead talks" />
                                        </div>
                                        <div className={styles.rightLogo}>
                                            <div>
                                                <span>Powered by</span>
                                                <img src={JioEventsLogo} alt="jio-events" />
                                            </div>
                                            <div>
                                                <span>Knowledge partner</span>
                                                <img src={SciativeLogo} alt="sciative" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* main section */}
                                    <div className="container">

                                        {
                                            showIframe ?
                                                <div>
                                                    <iframe src="https://rc.jioevents.com/attendee/sm-999b9bf0-9385-442e-958e-ea425d20d913/join/_fBxyBP8Fy62e7bc9570e81708079348733" referrerpolicy="no-referrer" allow="fullscreen"></iframe>
                                                </div>
                                                :
                                                <div className={styles.main}>
                                                    <div className={styles.leftContent}>
                                                        <h1 className={styles.heading}>
                                                            {webinar['title']}
                                                        </h1>
                                                        <div dangerouslySetInnerHTML={{ __html: webinar['desc'] }}>

                                                        </div>
                                                    </div>
                                                    <div className={styles.rightImg}>
                                                        <img src={pastWebinar ? webinar['pre-img']['pre-img-key'] : webinar['post-img']['post-img-key']} alt={pastWebinar ? webinar['pre-img']['alt'] : webinar['post-img']['alt']} />
                                                        {
                                                            pastWebinar &&
                                                            <button onClick={() => setShowModalVideoRequest(true)} type="button">Tune in now</button>
                                                        }
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>


                                <div className="container pb-5">

                                    {/* countdown section */}
                                    {
                                        !pastWebinar &&
                                        <Countdown date={webinar['publish_date']} time={webinar['publish_time']} />
                                    }

                                    {/* featured section */}
                                    <div className={styles.featured}>

                                        {
                                            webinar['featured_experts'].map((expert, index) => {
                                                return (
                                                    <div onClick={() => setSelectedExpert(expert)} key={index} className={styles.card}>
                                                        <div className={styles.imgContainer}>
                                                            <img src={expert['image']['key']} alt={expert['image']['alt_text']} />
                                                        </div>
                                                        <div className={styles.contentContainer}>
                                                            <h4>{expert['type'].toUpperCase()}</h4>
                                                            <h3>{expert['name']}</h3>
                                                            <div className={styles.socialIconsContainer}>
                                                                {
                                                                    expert['social_profiles']['linkedIn'] &&
                                                                    <SocialIcon href={expert['social_profiles']['linkedIn']} target="_bank" className={styles.socialIcon} url="www.linkedin.com" />
                                                                }
                                                                {
                                                                    expert['social_profiles']['twitter'] &&
                                                                    <SocialIcon href={expert['social_profiles']['twitter']} target="_bank" className={styles.socialIcon} url="www.twitter.com" />
                                                                }
                                                                {
                                                                    expert['social_profiles']['instagram'] &&
                                                                    <SocialIcon href={expert['social_profiles']['instagram']} target="_bank" className={styles.socialIcon} url="www.instagram.com" />
                                                                }
                                                                {
                                                                    expert['social_profiles']['facebook'] &&
                                                                    <SocialIcon href={expert['social_profiles']['facebook']} target="_bank" className={styles.socialIcon} url="www.facebook.com" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/* about section */}
                                    <div className={styles.about}>
                                        <h4>About the {selectedExpert['type']}</h4>
                                        <h4 className={styles.speakerTitle}>{selectedExpert['name']}</h4>
                                        <div>
                                            <p>
                                                {selectedExpert['bio']}
                                            </p>
                                        </div>
                                    </div>

                                    {/* guest speaker button */}
                                    <div className={styles.GuestBtnContainer}>
                                        <button onClick={() => setGuestModal(true)} className={styles.GuestBtn} type="button">
                                            Become a Guest Speaker
                                        </button>
                                    </div>

                                    {/* Recommended talks section */}
                                    <div className={styles.recommended}>
                                        <h4>Recommended Road Ahead Talks</h4>
                                        <div className={styles.cardContainer}>
                                            {
                                                webinar['talks_recommendations'].map((talk, index) => {
                                                    return (
                                                        // <Link to={`/road-ahead-talks/${talk.seo.pageURL}`}>
                                                            <div onClick={() => window.location.href = `/road-ahead-talks/${talk.seo.pageURL}`} key={index} className={styles.card}>
                                                                <div className={styles.imgContainer}>
                                                                    <img src={talk['pre-img']['pre-img-key']} alt={talk['pre-img']['alt']} />
                                                                </div>
                                                                <div className={styles.contentContainer}>
                                                                    <h4>{talk['title']}</h4>
                                                                    <span>{moment(talk['publish_date']).format("Do MMMM YYYY")}</span>
                                                                </div>
                                                            </div>
                                                        // </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                            </>
                    }

                    <Modal
                        show={guestModal}
                        onHide={() => setGuestModal(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        contentClassName="remove-white-bg"
                        backdrop="static"
                        centered
                    >
                        <Modal.Body className="p-0">
                            <CloseIcon onClick={() => setGuestModal(false)} className='modal-close-icon' />
                            <Form {...guestSpeakerProps} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={showModalVideoRequest}
                        onHide={() => setShowModalVideoRequest(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        contentClassName="remove-white-bg"
                        backdrop="static"
                        centered
                    >
                        <Modal.Body className="p-0">
                            <CloseIcon onClick={() => setShowModalVideoRequest(false)} className='modal-close-icon' />
                            <Form {...videoRequestProps} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={showModalIframe}
                        onHide={() => setShowModalIframe(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered
                        dialogClassName={styles.iFrameModal}
                    >
                        <Modal.Body>
                            <CloseIcon onClick={() => setShowModalIframe(false)} className='modal-close-icon' />
                            <h3>Thank you for unlocking our knowledge warehouse!</h3>
                            <div className="row bg-beige">
                                {
                                    videoLinks.youtube &&
                                    <div className="offset-md-3 col-md-6 col-sm-12">
                                        <iframe
                                            width="560"
                                            height="315"
                                            src={videoLinks.youtube}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen>
                                        </iframe>
                                    </div>
                                }
                                {/* {
                                    videoLinks.spotify &&
                                    <iframe
                                        style={{ borderRadius: '12px' }}
                                        src={videoLinks.spotify}
                                        width="624"
                                        height="351"
                                        frameBorder="0"
                                        allowFullScreen=""
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        loading="lazy">
                                    </iframe>
                                } */}
                            </div>
                        </Modal.Body>
                    </Modal>

                </div>
            </HelmetProvider>
        </>
    );
}

export default Webinar;