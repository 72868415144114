import {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from '../../components/Forms/Form';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const NewsCard = ({type, img, title, date, url, fileName}) => {

    const [pdfRequestModal, setPdfRequestModal] = useState(false);

    const pdfRequestProps = {
        template: 1,
        form_name: "route-reports-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "Sciative's Route Reports",
        content_upper: "Take a deep dive into the Indian intercity bus industry with our comprehensive route reports highlighting the supply and demand tendencies of said routes.",
        content_lower: "Bringing you intriguing insights on India's biggest bus routes!",
        recipients_group_id: 1,
        requestedFileKey: fileName,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                setPdfRequestModal(false)
                // toast.success('Form submitted successfully!', {
                //     position: "bottom-right",
                //     autoClose: 2500,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: false,
                //     draggable: false,
                //     theme: "colored",
                // });
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
            }
            else {
              toast.error(res.data)
            }
        }
    }

    return(
        <>
            <div className='m-2 news-card corner d-flex flex-column'>
                {
                    type === 'news' ?
                    <div className='img_card_div'>
                        <a target="_blank"  href={`//${url}`}>
                            <img className='mb-0 news-card-img' src={img} alt={title}/>
                        </a>
                </div>
                :
                    type === 'route-report' ?
                    <img onClick={() => setPdfRequestModal(true)} style={{cursor: 'pointer', borderTopRightRadius: '12px', borderTopLeftRadius: '12px'}} className='mb-0 news-card-img' src={img}/>
                :   
                <div className='h-50 p-0'>
                    <a target="_blank"  href={`//${url}`}>
                        <img style={{borderTopRightRadius: '12px', borderTopLeftRadius: '12px'}} className='mb-0 h-100 w-100' src={img}/>
                    </a>
                </div>
                }
                
                
                <div className='h-50 news-card-title-section p-3 d-flex flex-column justify-content-between'>
                    {
                        type === 'route-report' ?
                        <span style={{cursor: 'pointer'}} onClick={() => setPdfRequestModal(true)} className='bold small'>{title}</span>
                        :
                        <span className='bold small'><a target="_blank" className='text-black' href={`//${url}`}>{title}</a></span>
                    }
                    <span className='small-2 news-card-date'>{date}</span>
                </div>
            </div>


            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className='p-1'>
                    <CloseIcon style={{top: '10px', right: '10px'}} onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </>
    )
};

export default NewsCard;